import React, { Fragment, useState, useEffect, useRef } from "react";
import { Row, Col, Card, Button, Input, Radio, Modal, Form, Select, Tabs, Divider, DatePicker, Switch, Drawer } from "antd";
import { SearchOutlined, ArrowLeftOutlined, EditOutlined, TagsOutlined, CaretDownOutlined, CalendarOutlined } from "@ant-design/icons";
import { htmlPrint } from "../../../lib/printer/htmlPrinter";
import { xmlPrint } from "../../../lib/printer/xmlPrinter";
import { Scrollbars } from "react-custom-scrollbars";
import Delete from "../../../assets/images/delete.png";
import CashLogo from "../../../assets/images/cash.png";
import CashLogoWhite from "../../../assets/images/cashWhite.svg";
import closeIcon from "../../../assets/images/closeicon.png";
import closeX from "../../../assets/images/close-x.svg";
import Backspace from "../../../assets/images/backspac.png";
import DropdownCircle from "../../../assets/images/DropdownCircle.png";
import UserSearch from "../../../assets/images/mdi_user-search.svg";
import CircleCloseIcon from "../../../assets/images/remove.svg";
import View from "../../../assets/images/view.svg";
import SearchIcon from "../../../assets/images/searchIcon.svg";
import CustomerIcon from "../../../assets/images/customerIcon.png";
import AspectRatio from "react-aspect-ratio";
import "react-aspect-ratio/aspect-ratio.css";
import { useWindowDimensions } from "../../../lib/useViewport";
import "../../style.css";
import "../../globalStyles.css";
import data from "../../../constants/retailPos.json";
import Tick from "../../../assets/images/tick-small.svg";
import _ from "lodash";
import db from "../../../database";
import EditIcon from "../../../assets/images/ri_edit-line.svg"; 

const { Option } = Select;
const TabPane = Tabs.TabPane;

const PointOfSaleCoreModals = (props) => {
  const { width } = useWindowDimensions();
  const {
    paymentModalInputRef,
    displayCustomerSearch,
    closeCustomerSearch,
    setCustomerSearchType,
    customerSearchType,
    handleCustomerSearch,
    customerSearchInput,
    setCustomerSearchResults,
    setCustomerSearchInput,
    customerSearchResults,
    selectCustomer,
    showEditOldCustomerFields,
    showAddNewCustomerFields,
    setDisplayCustomerSearch,
    setDisplayAddNewCustomer,
    displayAddNewCustomer,
    form,
    addNewCustomer,
    setDisplayEditOldCustomer,
    displayEditOldCustomer,
    editOldCustomer,
    paymentModal,
    tillDataPaymentMethods,
    setSelectedPaymentMethod,
    openPaymentModalByCustomer,
    selectedPaymentMethod,
    cart,
    requestPayment,
    setCouponModalVisible,
    closePaymentModal,
    paymentModalLoyalityMessages,
    amount,
    onChangeAmount,
    processOrder,
    handleAmount,
    currencyType,
    editFlag,
    setEditFlag,
    // Order History Modal //
    displayOrderHistory,
    setDisplayOrderHistory,
    searchOrderHistory,
    showOrderHistory,
    setOrderHistoryInput,
    orderHistoryDetails,
    showOrderHistoryLine,
    selectedOrderHistoryLine,
    isPrintModeXML,
    syncOrders,
    tillData,
    changeOrderHistorySearchType,
    orderHistoryInput,
    paymentModalByCustomerState,
    posConfig,
    // Parked Bill Modal //
    filterdParkedList,
    displayParkedBillModal,
    searchParkedBill,
    handleParkedBillSearchInput,
    parkedBillSearchInput,
    selectParkedBill,
    closeParkedBillModal,
    cashManagementForm,
    handleChangeCurrency,
    roundOffValue,
    orderFlag,
    selectedEditOldCustomer,
    setIsInputFocused,
  } = props;

  const currenciesList = tillData.tillAccess.csBunit.currencies;

  const [toggleCustomer, setToggleCustomer] = useState(false)
  const [loyalityType, setLoyalityType] = useState([]);
  const [enableLoyality,setEnableLoyality] = useState(true);


  const customerSearchInputRef = useRef();
  useEffect(() => {
    if (displayCustomerSearch) {
      setTimeout(() => {
        customerSearchInputRef?.current?.focus();
      }, 100);
    }
  }, [displayCustomerSearch, customerSearchType]);

  const orderHistorySearchInputRef = useRef();
  useEffect(() => {
    if (displayOrderHistory) {
      setTimeout(() => {
        orderHistorySearchInputRef?.current?.focus();
      }, 100);
    }
  }, [displayOrderHistory, changeOrderHistorySearchType]);


  const paymentCardRef = useRef();

  useEffect(() => {
    if (paymentModal) {
      setTimeout(() => {
        paymentModalInputRef?.current?.focus();
      }, 100);
    }
  }, [paymentModal, selectedPaymentMethod]);

  const handleKey = (e) => {
    const { keyCode } = e;
    if (keyCode === 37) {
      paymentCardRef?.current?.focus();
    }
    if (keyCode === 46) {
      if (paymentModalActiveRef.current) {
        deleteAmountOnCart();
      }
    }
  };

  useEffect(async() => {
    let loyaliryData = await db.loyalityData.toArray();
    loyaliryData.map((item) => {
      if(item.isDefault === "Y"){
        setLoyalityType([item]);
      }
    })
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);


  const deleteAmountOnCart = () => {
    const pmName = document.activeElement.firstChild.firstChild.firstChild.firstChild.firstChild.firstChild.firstChild.children[1].innerHTML;
    const iN = cartForPaymentRef.current.payments.findIndex((paymentMade) => paymentMade.name === pmName);
    requestPayment(cartForPaymentRef.current.payments[iN], -cartForPaymentRef.current.payments[iN].amount, "allow");
  };

  const cartForPaymentRef = useRef();
  useEffect(() => {
    cartForPaymentRef.current = cart;
  }, [cart]);

  const paymentModalActiveRef = useRef();
  useEffect(() => {
    paymentModalActiveRef.current = paymentModal;
  }, [paymentModal]);

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    // setLoading(false);
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderThumbHorizontalScroll = ({ style, ...props }) => {
    const thumbStyle = {
      // backgroundColor: "#c1c1c1",
      // borderRadius: "5px",
      width: "0px",
    };
    // setLoading(false);
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };


  const addCustomer = ()=>{
    const value = form.getFieldsValue();
    addNewCustomer(value);
  }
  
  const editCustomer = () =>{
    const value = form.getFieldsValue();
    editOldCustomer(value)
  }
  const onSwitchChange = checked => {
    form.setFieldsValue({ loyality: checked });
    setEnableLoyality(checked)
  };

  return (
    <Fragment>
      {/* CUSTOMER SEARCH MODAL BEGIN*/}
      <Modal
        // visible={displayCustomerSearch}
        onCancel={closeCustomerSearch}
        closable={false}
        footer={null}
        // width="40%"
        centered
        keyboard={false}
        bodyStyle={{ padding: 15, backgroundColor: "#F3F4F9" }}
      >
        <div style={{ height: "60vh" }}>
          <Row>
            <Col span={24} style={{ paddingBottom: "2vh", textAlign: "center" }}>
              <span style={data.posScreenStyles.addEditCustomerModal.addSearchCustLabel}>Search For Customer</span>
            </Col>
            <Col span={24}>
              <Input
                ref={customerSearchInputRef}
                placeholder="Search"
                size="large"
                style={data.posScreenStyles.customerModal.customerSearchInput}
                className="customerSearchInput"
                prefix={<img src={SearchIcon} onClick={handleCustomerSearch} style={data.posScreenStyles.customerModal.searchIcon} />}
                value={customerSearchInput}
                suffix={<img src={CircleCloseIcon} alt="" style={data.posScreenStyles.customerModal.searchIcon} onClick={closeCustomerSearch} className="remove_btn" />}
                onPressEnter={handleCustomerSearch}
                onChange={(e) => {
                  e.target.value === ""
                    ? (() => {
                      setCustomerSearchResults();
                      setCustomerSearchInput(e.target.value);
                    })()
                    : setCustomerSearchInput(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={24}>
              {customerSearchResults === undefined ? (
                <div style={data.posScreenStyles.header.left}>
                  <div bordered style={data.posScreenStyles.customerModal.customerSearchListEmpty} />
                </div>
              ) : customerSearchResults !== undefined && customerSearchResults.length > 0 ? (
                <div style={data.posScreenStyles.header.left}>
                  <div style={data.posScreenStyles.customerModal.customerSearchList}>
                    <p style={{ textAlign: "left", fontSize: "1.8vh", padding: "1vh 0 1vh 1vw", fontWeight: "500", color: "#2F3856", borderBottom: "1px solid rgba(0, 0, 0, 0.12)", margin: "0 1vw" }}>Customers</p>
                    <Scrollbars className="customerCardScroll">
                      {customerSearchResults.map((customerSearchResult, index) => (
                        <div
                          tabIndex={0}
                          onKeyPress={(e) => (e.charCode === 13 ? selectCustomer(index) : null)}
                          key={`${index}`}
                          style={data.posScreenStyles.customerModal.customerDetailsCard}
                        >
                          {/* <Row>
                          <Col span={19} offset={3} onClick={() => selectCustomer(index)}>
                          </Col>

                        </Row> */}
                          <Row style={{ display: "flex", alignItems: "center", justifyContent: "center", borderBottom: "1px solid #0000001F" }} >
                            <Col span={2} offset={1}>
                              <span style={{ padding: "0.8vw 1.2vw", backgroundColor: "#D9D9D9", color: "rgba(0, 0, 0, 0.59)", textAlign: "center", borderRadius: "4px", fontWeight: "600" }}>{customerSearchResult.name.substring(0, 1)}</span>
                            </Col>
                            <Col span={17} offset={1} onClick={() => selectCustomer(index)}>
                              <p style={data.posScreenStyles.customerModal.customerName}>{customerSearchResult.name}</p>
                              <p style={data.posScreenStyles.customerModal.customerStatus}>{customerSearchResult.mobileNo}</p>
                            </Col>


                            <Col span={3} style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                              <img onClick={() => showEditOldCustomerFields(customerSearchResult)} src={View} style={{ height: "3vh" }} />
                              <EditOutlined style={data.posScreenStyles.customerModal.dropdownCircleIcon} onClick={() => { showEditOldCustomerFields(customerSearchResult); setEditFlag(true); }} />
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </Scrollbars>
                  </div>
                </div>
              ) : (
                <div style={data.posScreenStyles.header.left}>
                  <div bordered style={data.posScreenStyles.customerModal.customerSearchListEmpty}>
                    <Row>
                      <p style={data.posScreenStyles.customerModal.noResultsText}>No results found</p>
                    </Row>
                    <Row justify="center" align="center">
                      <img src={CustomerIcon} alt="CustomerIcon" style={{ height: "1.7vh", alignSelf: "center", paddingRight: "0.5vw" }} /> <span style={data.posScreenStyles.customerModal.addNewCustomer} onClick={showAddNewCustomerFields}>
                        Add Customer
                      </span>
                    </Row>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>

      </Modal>
      <Drawer
        placement="bottom"
        title={
          <div style={{ display: "flex", alignItems: "center", height: "2vh" }}>
            <p style={{ fontSize: "1.5vw", marginTop: "3.6vh", fontWeight: "500", color: "#0F0718", flex: 1, marginLeft:"35vw" }}>Search for Customer</p>
            <img src={closeX} onClick={closeCustomerSearch} style={{ marginLeft: "auto", paddingBottom: "4vh",paddingTop:"2vh", cursor: "pointer", width:"1.5vw",}} />
          </div>
        }
        visible={displayCustomerSearch}
        className="addCustomer"
        bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
        onClose={closeCustomerSearch}
        height="78vh">
        {/* <div > */}
        <Row style={{ marginTop: "0.1%", height:"6.5vh",  }}>
          <Col span={24} style={{ padding: "0 5vw" }}>
            <Input
              ref={customerSearchInputRef}
              placeholder="Search"
              size="large"
              style={data.posScreenStyles.customerModal.customerSearchInput}
              className="customerSearchInput"
              prefix={<img src={UserSearch} style={data.posScreenStyles.customerModal.searchIcon} />}
              value={customerSearchInput}
              suffix={<img src={CircleCloseIcon} alt="" style={data.posScreenStyles.customerModal.searchIcon} onClick={closeCustomerSearch} className="remove_btn" />}
              // onPressEnter={handleCustomerSearch}
              onFocus={()=>{setIsInputFocused(true);}}
              onChange={(e) => {
                e.target.value === ""
                  ? (() => {
                    setCustomerSearchResults();
                    setCustomerSearchInput(e.target.value);
                  })()
                  : setCustomerSearchInput(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "1%" }}>
        <Col span={20} style={{marginLeft:"5vw"}}>
            {customerSearchResults === undefined ? (
              <div style={data.posScreenStyles.header.left}>
                <div bordered style={data.posScreenStyles.customerModal.customerSearchListEmpty} />
              </div>
            ) : customerSearchResults !== undefined && customerSearchResults.length > 0 ? (
              <div style={data.posScreenStyles.header.left}>
                <div style={data.posScreenStyles.customerModal.customerSearchList}>
                <p style={{ textAlign: "left", fontSize: "1.8vh", padding: "1vh 0 1vh 1vw", fontWeight: "700", color: "#2F3856", borderBottom: "1px solid rgba(0, 0, 0, 0.12)", margin: "0 2vw" }}>Customers</p>
                <Scrollbars className="customerCardScroll">
                    {customerSearchResults.map((customerSearchResult, index) => (
                      <div
                        tabIndex={0}
                        onKeyPress={(e) => (e.charCode === 13 ? selectCustomer(index) : null)}
                        key={`${index}`}
                        style={data.posScreenStyles.customerModal.customerDropDownDetailsCard}
                      >
                        <Row style={{ display: "flex", alignItems: "center", justifyContent: "center", borderBottom: "1px solid #0000001F", paddingBottom: "0.1%",}} >
                        <Col span={1} style={{paddingBottom:"1vh"}} >
                            <span style={{ padding: "0.8vw 1.2vw", backgroundColor: "rgba(146, 144, 152, 0.5)", color: "rgba(15, 7, 24, 1)", textAlign: "center", borderRadius: "4px", fontWeight: "800" }}>{customerSearchResult.name.substring(0, 1)}</span>
                          </Col>
                          <Col span={18} onClick={() => selectCustomer(index)} style={{paddingLeft:"1.2vw",}}>
                          <p style={data.posScreenStyles.customerModal.customerName}>{customerSearchResult.name} - {customerSearchResult.b2cCustomerSegment.name}</p>
                            <p style={data.posScreenStyles.customerModal.customerStatus}>{customerSearchResult.mobileNo}</p>
                          </Col>
                          <Col span={4} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "10px",paddingTop:"1vh",paddingBottom:"2.5vh" }}>
                            <img onClick={() => showEditOldCustomerFields(customerSearchResult)} src={View} style={{ height: "3vh", marginRight: "5px" }} />
                            <img onClick={() => { showEditOldCustomerFields(customerSearchResult); setEditFlag(true); }}  src={EditIcon} style={{ height: "3vh", marginRight: "5px",marginLeft:"0.5vw" }} />
                            {/* <EditOutlined style={data.posScreenStyles.customerModal.dropdownCircleIcon} onClick={() => { showEditOldCustomerFields(customerSearchResult); setEditFlag(true); }} /> */}
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Scrollbars>                
                  <Row justify="center" align="center" style={{position:"relative",bottom:"-4vh"}}>
                    <img src={CustomerIcon} alt="CustomerIcon" style={{ height: "1.7vh", alignSelf: "center", paddingRight: "0.5vw" }} /> <span style={data.posScreenStyles.customerModal.addNewCustomer} onClick={showAddNewCustomerFields}>
                      Add Customer
                    </span>
                  </Row>
                </div>
              </div>
            ) : (
              <div style={data.posScreenStyles.header.left}>
                <div bordered style={data.posScreenStyles.customerModal.customerSearchListEmpty}>
                  <Row>
                    <p style={data.posScreenStyles.customerModal.noResultsText}>No results found</p>
                  </Row>
                  <Row justify="center" align="center">
                    <img src={CustomerIcon} alt="CustomerIcon" style={{ height: "1.7vh", alignSelf: "center", paddingRight: "0.5vw" }} />
                     <span style={data.posScreenStyles.customerModal.addNewCustomer} onClick={showAddNewCustomerFields}>
                      Add Customer
                    </span>
                  </Row>
                </div>
              </div>
            )}
          </Col>
        </Row>
        {/* </div> */}
      </Drawer>
      {/* CUSTOMER SEARCH MODAL END*/}

      {/* ADD NEW CUSTOMER MODAL BEGIN*/}
      <Modal
        // visible={displayAddNewCustomer}
        onCancel={() => {
          setDisplayCustomerSearch(true);
          setDisplayAddNewCustomer(false);
          setCustomerSearchInput("");
          setCustomerSearchResults();
        }}
        footer={null}
        width="53%"
        closable={false}
        bodyStyle={{ padding: 20, paddingTop: 0, backgroundColor: "#F3F4F9" }}
      >
        <div>
          <div style={data.posScreenStyles.customerModal.customerDetailsCard}>
            <Row>
              <Col span={24}>
                <span style={data.posScreenStyles.addEditCustomerModal.addNewCustLabel}>Add Customer</span>
                <Button.Group>
                  <Button style={{ backgroundColor: !toggleCustomer ? '#2F3856' : '#fff', color: toggleCustomer ? '#929098' : '#fff', fontSize: "0.8vw", fontWeight: "400", height: "4vh", borderRadius: "3px", borderTopRightRadius: 0, borderBottomRightRadius: 0 }} onClick={() => setToggleCustomer(false)}>Contact</Button>
                  <Button style={{ backgroundColor: toggleCustomer ? '#2F3856' : '#fff', color: !toggleCustomer ? '#929098' : '#fff', fontSize: "0.8vw", fontWeight: "400", height: "4vh", borderRadius: "3px", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => setToggleCustomer(true)}>Details</Button>
                </Button.Group>
                <img
                  onClick={() => {
                    setDisplayCustomerSearch(true);
                    setDisplayAddNewCustomer(false);
                    setCustomerSearchInput("");
                    setCustomerSearchResults();
                  }}
                  style={{ float: "right", cursor: "pointer" }} src={closeX} alt="" />
              </Col>
            </Row>
          </div>

          <Form key={"addCustomer"} form={form} name="addCustomer" onFinish={addNewCustomer}>
            <Scrollbars
              thumbSize={100}
              // renderView={renderView}
              renderThumbHorizontal={renderThumbHorizontalScroll}
              renderThumbVertical={renderThumb}
              style={{ height: "40vh" }}>
              <div style={{ display: toggleCustomer ? "block" : "none" }} >

                <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.35)", marginBottom: "1.5vh" }}>
                  <Col span={24}>
                    <label style={{ fontSize: "1.2vh", fontWeight: 600, letterSpacing: "1.5px" }}>MAILING ADDRESS</label>
                  </Col>
                </Row>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Street</label>
                    <Form.Item name="street" style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Street" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>City</label>
                    <Form.Item name="city" style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="City" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>State</label>
                    <Form.Item
                      name="state"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter State" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Country</label>
                    <Form.Item
                      name="country"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Country" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Pincode</label>
                    <Form.Item
                      name="pincode"
                      rules={[
                        {
                          required: false,
                          pattern: new RegExp(/\d+/g),
                          message: "Please Input Pincode",
                        },
                      ]}
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: "1.5vh" }}>
                  <Col span={24}>
                    <label style={{ fontSize: "1.2vh", fontWeight: 600, letterSpacing: "1.5px" }}>SETTINGS</label>
                  </Col>
                </Row>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Loyalty</label>
                    <Form.Item
                      name="loyality"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Switch onChange={onSwitchChange} defaultChecked/>&nbsp;{" "}
                      <span style={{ fontFamily: "Inter", fontSize: "1vw", fontWeight: 500 }}>Enable loyalty for this customer </span>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Program</label>
                    {enableLoyality && <Form.Item
                      name="program"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Select disabled= {true}>
                          <Option key={loyalityType[0]?.loyaltylevelId} value={loyalityType[0]?.loyaltylevelId}>{loyalityType[0]?.name}</Option>
                      </Select>
                    </Form.Item>}
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: "1.5vh" }}>
                  <Col span={24}>
                    <label style={{ fontSize: "1.2vh", fontWeight: 600, letterSpacing: "1.5px" }}>ADDITIONAL INFORMATION</label>
                  </Col>
                </Row>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date</label>
                    <Form.Item name="AnniversaryDate" style={{ border: "0" }}>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        className="cutomerAniversaryDate"
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>

                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Tax ID</label>
                    <Form.Item name="taxID" style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Tax ID" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    {/* <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date </label>
                <Form.Item name="anniversaryDate" rules={[{ required: true, message: "Please Input Anniversary Date" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                  <DatePicker placeholder="Anniversary Date" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                </Form.Item> */}
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Birthday Date</label>
                    <Form.Item name="BirthdayDate" style={{ border: "0" }}>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        className="cutomerDobInput"
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>

                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Gender</label>
                    <Form.Item
                      name="gender"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Select>
                        <Option key={"MAL"} value="MAL">
                          Male
                        </Option>
                        <Option key={"FEM"} value="FEM">
                          Female
                        </Option>
                        <Option key={"TRANS"} value="TRANS">
                          Transgender
                        </Option>

                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div style={{ display: toggleCustomer ? "none" : "block" }}>
                <Row>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>First Name</label>
                    <Form.Item name="name" rules={[{ required: true, message: "Please Input First Name" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="First Name" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Last Name</label>
                    <Form.Item name="lastName" style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Last Name" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Email</label>
                    <Form.Item
                      name="email"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Email" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Contact Number</label>
                    <Form.Item
                      name="mobile"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Contact Number" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>

                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Pincode</label>
                    <Form.Item
                      name="pincode"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>


            </Scrollbars>
            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xxl={{ span: 24 }} >
                <Form.Item
                  style={{ paddingTop: "3vh" }}
                >
                  <Button className="customerDetailsSubmitBtnBtn" style={data.posScreenStyles.addEditCustomerModal.customerDetailsSubmitBtn} htmlType="submit">
                    Create New Customer
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
      <Drawer
        bodyStyle={{ padding: "0 24px" }}
        closable={false}
        placement="bottom"
        title={
          <div style={data.posScreenStyles.customerModal.customerDetailsCard}>
            <Row>
              <Col span={2} />
              <Col span={22}>
                <span style={data.posScreenStyles.addEditCustomerModal.addNewCustLabel}>Add Customer</span>
                <Button.Group>
                  <Button style={{ backgroundColor: !toggleCustomer ? '#2F3856' : '#fff', color: toggleCustomer ? '#929098' : '#fff', fontSize: "0.8vw", fontWeight: "400", height: "5vh", borderRadius: "3px", borderTopRightRadius: 0, borderBottomRightRadius: 0 }} onClick={() => setToggleCustomer(false)}>Contact</Button>
                  <Button style={{ backgroundColor: toggleCustomer ? '#2F3856' : '#fff', color: !toggleCustomer ? '#929098' : '#fff', fontSize: "0.8vw", fontWeight: "400", height: "5vh", borderRadius: "3px", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => setToggleCustomer(true)}>Details</Button>
                </Button.Group>
                <img
                  onClick={() => {
                    setDisplayCustomerSearch(true);
                    setDisplayAddNewCustomer(false);
                    setCustomerSearchInput("");
                    setCustomerSearchResults();
                    setToggleCustomer(false);
                    form.resetFields();
                  }}
                  style={{ float: "right", cursor: "pointer", marginTop: "-4vh",marginRight:"-1.5vw", height:"2.5vh" }} src={closeX} alt="" />
              </Col>
            </Row>
          </div>
        }
        visible={displayAddNewCustomer}
        className="addCustomer"
        onClose={() => {
          setDisplayCustomerSearch(true);
          setDisplayAddNewCustomer(false);
          setCustomerSearchInput("");
          setCustomerSearchResults();
        }}
        height="87vh"
      >
        <div>
          <Form key={"addCustomer"} form={form} name="addCustomer" style={{ padding: "0 5%" }} >
            <Scrollbars
              // renderView={renderView}
              renderThumbHorizontal={renderThumbHorizontalScroll}
              renderThumbVertical={renderThumb}
              style={{ height: "58vh" }}>
              <div style={{ display: toggleCustomer ? "block" : "none" }} >

              <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.35)", marginBottom: "1.5vh", margin: '0 2vw',width:"80.5vw", marginBottom: "2vh", }}>
                  <Col span={24}>
                    <label style={{ fontSize: "1.8vh", fontWeight: 500, letterSpacing: "1.5px", marginTop:"2vh" }}>MAILING ADDRESS</label>
                  </Col>
                </Row>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "3vh"  }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Street</label>
                    <Form.Item name="street" rules={[{
                      // required: true, message: "Please Input Street"
                    }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Street" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "3vh"  }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>City</label>
                    <Form.Item name="city" style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="City" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw",marginTop:"3.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>State</label>
                    <Form.Item
                      name="state"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input readOnly className="customerDetailsInput" placeholder="Enter State" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw",marginTop:"3.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Country</label>
                    <Form.Item
                      name="country"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input readOnly className="customerDetailsInput" placeholder="Enter Country" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw",marginTop:"3.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Pincode</label>
                    <Form.Item
                      name="pincode"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: "1.5vh", margin: '0 2vw', marginBottom: "2vh",marginTop:"4.5vh", width:"80.2vw" }}>
                  <Col span={24}>
                    <label style={{ fontSize: "1.8vh", fontWeight: 600, letterSpacing: "1.5px" }}>LOYALTY</label>
                  </Col>
                </Row>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Loyalty</label>
                    <Form.Item
                      name="loyality"  // Add a name attribute
                      valuePropName="checked" // For Switch component
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                      initialValue={true}
                    >
                      {/* Use Switch component */}
                      <Switch onChange={onSwitchChange} defaultChecked/>
                      <span style={{ fontFamily: "Inter", fontSize: "1vw", fontWeight: 500, marginLeft:"1vw" }}>Enable loyalty for this customer </span>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Program</label>
                    {enableLoyality && <Form.Item
                      name="program"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Select disabled= {true}>
                          <Option key={loyalityType[0]?.loyaltylevelId} value={loyalityType[0]?.loyaltylevelId}>{loyalityType[0]?.name}</Option>
                      </Select>
                    </Form.Item>}
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: "1.5vh", margin: '0 2vw', marginBottom: "2vh", width:"80.2vw" }}>
                  <Col span={24}>
                    <label style={{ fontSize: "1.8vh", fontWeight: 600, letterSpacing: "1.5px" }}>GENERAL INFORMATION</label>
                  </Col>
                </Row>
                
                <Row >
                <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date</label>
                    <Form.Item name="anniversaryDate"
                      style={{ border: "0", height:"3vh" }}>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        className="cutomerDobInput"
                        format="DD/MM/YYYY"
                        style={{ height: '7.3vh', border: 'none', marginTop: 10, width: '95%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Tax ID</label>
                    <Form.Item name="taxID"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Tax ID" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw",marginTop: "3vh"  }}>
                    {/* <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date </label>
                <Form.Item name="anniversaryDate" rules={[{ required: true, message: "Please Input Anniversary Date" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                  <DatePicker placeholder="Anniversary Date" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                </Form.Item> */}
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Date of Birth</label>
                    <Form.Item name="BirthdayDate"

                      style={{ border: "0" }}>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        className="cutomerDobInput"
                        format="DD/MM/YYYY"
                        style={{ width: "95%", height: "7.3vh", margin: "10px 0" }}
                      />
                    </Form.Item>

                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw",marginTop: "3vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Gender</label>
                    <Form.Item
                      name="gender"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}

                    >
                      <Select size="large" className="genderSelect" >
                        <Option key={"MAL"} value="MAL"  >
                          Male
                        </Option>
                        <Option key={"FEM"} value="FEM">
                          Female
                        </Option>
                        <Option key={"TRA"} value="TRA">
                          Transgender
                        </Option>

                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div style={{ display: toggleCustomer ? "none" : "block" }}>
                <Row>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw",marginTop:"2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>First Name</label>
                    <Form.Item name="name" rules={[{ required: true, message: "Please Input First Name" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="First Name" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw",marginTop:"2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Last Name</label>
                    <Form.Item name="lastName" style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Last Name" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw",marginTop:"4vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Email</label>
                    <Form.Item
                      name="email"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Email" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw",marginTop:"4vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Contact Number</label>
                    <Form.Item
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(/^[0-9]\d*$/),
                          message: "Please Input Valid Contact Number",
                        },
                      ]}
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Contact Number" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>

                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw",marginTop:"4vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Pincode</label>
                    <Form.Item
                      name="pincode"
                      rules={[
                        {
                          required: false,
                          pattern: new RegExp(/\d+/g),
                          message: "Please Input Pincode",
                        },
                      ]}
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div style={{ display: toggleCustomer ? "block" : "none", backgroundColor: "#fff", padding: "2vh 2vw" ,margin:"2vh 2vw 2vh 2vw", width:"80.6vw"}}>
                  <Row>
                    <p style={{ fontSize: "2.5vh", margin: 0, fontWeight: "600" }}>Additional Information</p>
                    <Col span={24}>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Last Visit Date
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.lastVisitDate ? cart?.customer?.lastVisitDate || "0" : selectedEditOldCustomer?.lastVisitDate || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          No Of Visits
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.noOfVisits ? cart?.customer?.noOfVisits || "0" : selectedEditOldCustomer?.noOfVisits || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Last Billing Amount
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.lastBillingAmount ? cart?.customer?.lastBillingAmount || "0" : selectedEditOldCustomer?.lastBillingAmount || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Average Basket Value
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.averageBasketValue ? cart?.customer?.averageBasketValue || "0" : selectedEditOldCustomer?.averageBasketValue || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Loyalty Balance Points
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.retlLoyaltyBalance ? cart?.customer?.retlLoyaltyBalance || " " : selectedEditOldCustomer?.retlLoyaltyBalance || " "}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Credit Balance
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.creditLimit ? cart?.customer?.creditLimit || " " : selectedEditOldCustomer?.creditLimit || " "}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
              </div>

            </Scrollbars>
            <Row>
              <Col xs={{ span: 9 }} lg={{ span: 19 }} xl={{ span: 19 }} xxl={{ span: 19 }} />
              <Col xs={{ span: 14 }} lg={{ span: 4 }} xl={{ span: 4 }} xxl={{ span: 4 }} >
                <Form.Item
                  style={{ paddingTop: "3vh",paddingRight:"1vw" }}
                >
                  <Button className="customerDetailsSubmitBtnBtn" style={data.posScreenStyles.addEditCustomerModal.customerDetailsSubmitBtn} onClick={addCustomer}>
                    Create New Customer
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Drawer>
      {/* ADD NEW CUSTOMER MODAL END*/}

      {/* EDIT CUSTOMER MODAL BEGIN*/}
      <Modal
        // visible={displayEditOldCustomer}
        onCancel={() => {
          form.resetFields();
          closeCustomerSearch();
          setDisplayEditOldCustomer(false);
          setEditFlag(false)
        }}
        footer={null}
        width="53%"
        closable={false}
        bodyStyle={{ padding: 20, backgroundColor: "#F3F4F9" }}
      >
        {displayEditOldCustomer && editFlag ? (
          <Form key={"editCustomer"} form={form} name="editCustomer" onFinish={editOldCustomer}>
            <Row style={{ paddingBottom: "2vh" }}>
              <Col span={24}>
                <span style={data.posScreenStyles.addEditCustomerModal.addNewCustLabel}>Edit Customer</span>
                <Button.Group>
                  <Button style={{ backgroundColor: !toggleCustomer ? '#2F3856' : '#fff', color: toggleCustomer ? '#929098' : '#fff', fontSize: "0.8vw", fontWeight: "400", height: "4vh", borderRadius: "3px", borderTopRightRadius: 0, borderBottomRightRadius: 0 }} onClick={() => setToggleCustomer(false)}>Contact</Button>
                  <Button style={{ backgroundColor: toggleCustomer ? '#2F3856' : '#fff', color: !toggleCustomer ? '#929098' : '#fff', fontSize: "0.8vw", fontWeight: "400", height: "4vh", borderRadius: "3px", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => setToggleCustomer(true)}>Details</Button>
                </Button.Group>
                <img
                  onClick={() => {
                    form.resetFields();
                    closeCustomerSearch();
                    setDisplayEditOldCustomer(false);
                    setEditFlag(false)
                  }}
                  style={{ float: "right", cursor: "pointer" }} src={closeX} alt="" />
              </Col>
            </Row>
            <Scrollbars
              thumbSize={100}
              // renderView={renderView}
              renderThumbHorizontal={renderThumbHorizontalScroll}
              renderThumbVertical={renderThumb}
              style={{ height: "40vh" }}>
              <div style={{ display: toggleCustomer ? "block" : "none" }}>

                <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.35)", marginBottom: "1.5vh" }}>
                  <Col span={24}>
                    <label style={{ fontSize: "1.2vh", fontWeight: 600, letterSpacing: "1.5px" }}>MAILING ADDRESS</label>
                  </Col>
                </Row>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Street</label>
                    <Form.Item name="street" rules={[{ required: true, message: "Please Input Street" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Street" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>City</label>
                    <Form.Item name="city" rules={[{ required: true, message: "Please Input City" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="City" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>State</label>
                    <Form.Item
                      name="state"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input readOnly className="customerDetailsInput" placeholder="Enter State" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Country</label>
                    <Form.Item
                      name="country"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input readOnly className="customerDetailsInput" placeholder="Enter Country" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Pincode</label>
                    <Form.Item
                      name="pincode"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: "1.5vh" }}>
                  <Col span={24}>
                    <label style={{ fontSize: "1.2vh", fontWeight: 600, letterSpacing: "1.5px" }}>SETTINGS</label>
                  </Col>
                </Row>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Loyalty</label>
                    {enableLoyality && <Form.Item
                      name="program"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Select disabled= {true}>
                          <Option key={loyalityType[0]?.loyaltylevelId} value={loyalityType[0]?.loyaltylevelId}>{loyalityType[0]?.name}</Option>
                      </Select>
                    </Form.Item>}
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Program</label>
                    <Form.Item
                      name="program"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Select >
                          <Option key={loyalityType[0]?.loyaltylevelId} value={loyalityType[0]?.loyaltylevelId}>{loyalityType[0]?.name}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: "1.5vh" }}>
                  <Col span={24}>
                    <label style={{ fontSize: "1.2vh", fontWeight: 600, letterSpacing: "1.5px" }}>ADDITIONAL INFORMATION</label>
                  </Col>
                </Row>
                <Row >
                  {/* <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date</label>
                    <Form.Item name="anniversaryDate" style={{ border: "0" }}>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        className="cutomerDobInput"
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>

                  </Col> */}
                  {/* <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date</label>
                    <Form.Item name="AnniversaryDate" style={{ border: "0" }}>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        className="cutomerAniversaryDate"
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>

                  </Col> */}
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date</label>
                    <Form.Item name="AnniversaryDate" style={{ border: "0" }}>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        className="cutomerAniversaryDate"
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>

                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Tax ID</label>
                    <Form.Item name="taxID" rules={[{
                      //  required: true, 
                      // message: "Please Input Tax ID" 
                    }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Tax ID" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    {/* <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date </label>
               <Form.Item name="anniversaryDate" rules={[{ required: true, message: "Please Input Anniversary Date" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                 <DatePicker placeholder="Anniversary Date" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
               </Form.Item> */}
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Birthday</label>
                    <Form.Item name="birthday" rules={[{
                      // required: true, 
                      // message: 'Please Input Birthday Date' 
                    }]} style={{ border: "0" }}>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        className="cutomerDobInput"
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>

                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Gender</label>
                    <Form.Item
                      name="gender"

                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Gender" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  {/* <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Gender</label>
                    <Form.Item
                      name="gender"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      {undefined}
                      <Select>
                        <Option key={"MAL"} value="MAL">
                          Male
                        </Option>
                        <Option key={"FEM"} value="FEM">
                          Female
                        </Option>
                        <Option key={"TRANS"} value="TRANS">
                          Transgender
                        </Option>

                      </Select>
                    </Form.Item>
                  </Col> */}
                </Row>
              </div>
              <div style={{display: toggleCustomer ? "block" : "none"}} >
              <Row>
                    <p style={{ fontSize: "2.5vh", margin: 0, fontWeight: "600" }}>Additional Information</p>
                    <Col span={24}>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Last Visit Date
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.lastVisitDate ? cart?.customer?.lastVisitDate || "0" : selectedEditOldCustomer?.lastVisitDate || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          No Of Visits
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.noOfVisits ? cart?.customer?.noOfVisits || "0" : selectedEditOldCustomer?.noOfVisits || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Last Billing Amount
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.lastBillingAmount ? cart?.customer?.lastBillingAmount || "0" : selectedEditOldCustomer?.lastBillingAmount || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Average Basket Value
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.averageBasketValue ? cart?.customer?.averageBasketValue || "0" : selectedEditOldCustomer?.averageBasketValue || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Loyalty Balance Points
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.retlLoyaltyBalance ? cart?.customer?.retlLoyaltyBalance || " " : selectedEditOldCustomer?.retlLoyaltyBalance || " "}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Credit Balance
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.creditLimit ? cart?.customer?.creditLimit || " " : selectedEditOldCustomer?.creditLimit || " "}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                </div>
              <div style={{ display: toggleCustomer ? "none" : "block" }}>
                <Row>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>First Name</label>
                    <Form.Item name="editName" rules={[{ required: true, message: "Please Input Name" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Enter Name" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Last Name</label>
                    <Form.Item
                      name="editLastName"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Enter Last Name" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Email</label>
                    <Form.Item
                      name="editEmail"
                      rules={[
                        {
                          required: false,
                          pattern: new RegExp(/\S+@\S+\.\S+/),
                          message: "Please Input Valid Email",
                        },
                      ]}
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Email" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Contact Number</label>
                    <Form.Item
                      name="editMobile"
                      rules={[
                        {
                          required: false,
                          pattern: new RegExp(/\d+/g),
                          message: "Please Input Mobile",
                        },
                      ]}
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Contact Number" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Pincode</label>
                    <Form.Item
                      name="editPincode"
                      rules={[
                        {
                          required: false,
                          pattern: new RegExp(/\d+/g),
                          message: "Please Input Pincode",
                        },
                      ]}
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Scrollbars>
            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xxl={{ span: 24 }} >
                <Form.Item
                  style={{ paddingTop: "3vh" }}
                >
                  <Button className="customerDetailsSubmitBtnBtn" style={data.posScreenStyles.addEditCustomerModal.customerDetailsSubmitBtn} htmlType="submit">
                    Save & Proceed
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          toggleCustomer ? (
            <Form key={"viewCustomer"} name="viewCustomer" form={form}>
              <Row style={{ paddingBottom: "2vh" }}>
                <Col span={24}>
                  <span style={data.posScreenStyles.addEditCustomerModal.addNewCustLabel}>View Customer</span>
                  <Button.Group>
                    <Button style={{ backgroundColor: !toggleCustomer ? '#2F3856' : '#fff', color: toggleCustomer ? '#929098' : '#fff', fontSize: "0.8vw", fontWeight: "400", height: "4vh", borderRadius: "3px", borderTopRightRadius: 0, borderBottomRightRadius: 0 }} onClick={() => setToggleCustomer(false)}>Contact</Button>
                    <Button style={{ backgroundColor: toggleCustomer ? '#2F3856' : '#fff', color: !toggleCustomer ? '#929098' : '#fff', fontSize: "0.8vw", fontWeight: "400", height: "4vh", borderRadius: "3px", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => setToggleCustomer(true)}>Details</Button>
                  </Button.Group>
                  <img
                    onClick={() => {
                      form.resetFields();
                      closeCustomerSearch();
                      setDisplayEditOldCustomer(false);
                      setEditFlag(false)
                    }}
                    style={{ float: "right", cursor: "pointer" }} src={closeX} alt="" />
                </Col>
              </Row>
              <Scrollbars
                thumbSize={100}
                // renderView={renderView}
                renderThumbHorizontal={renderThumbHorizontalScroll}
                renderThumbVertical={renderThumb}
                style={{ height: "37vh" }}>
                <div style={{ display: toggleCustomer ? "block" : "none" }}>
                  <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.35)", marginBottom: "1.5vh" }}>
                    <Col span={24}>
                      <label style={{ fontSize: "1.2vh", fontWeight: 600, letterSpacing: "1.5px" }}>MAILING ADDRESS</label>
                    </Col>
                  </Row>
                  <Row >
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Street</label>
                      <Form.Item disabled={true} name="street" rules={[{ required: true, message: "Please Input Street" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                        <Input disabled={true} className="customerDetailsInput" placeholder="Street" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>City</label>
                      <Form.Item name="city" disabled={true} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                        <Input disabled={true} className="customerDetailsInput" placeholder="City" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>State</label>
                      <Form.Item
                        name="state"
                        style={data.posScreenStyles.addEditCustomerModal.formItem}
                      >
                        <Input disabled={true} className="customerDetailsInput" placeholder="Enter State" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Country</label>
                      <Form.Item
                        name="country"
                        disabled={true}
                        style={data.posScreenStyles.addEditCustomerModal.formItem}
                      >
                        <Input disabled={true} className="customerDetailsInput" placeholder="Enter Country" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Pincode</label>
                      <Form.Item
                        name="pincode"
                        disabled={true}
                        style={data.posScreenStyles.addEditCustomerModal.formItem}
                      >
                        <Input disabled={true} className="customerDetailsInput" placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: "1.5vh" }}>
                    <Col span={24}>
                      <label style={{ fontSize: "1.2vh", fontWeight: 600, letterSpacing: "1.5px" }}>SETTINGS</label>
                    </Col>
                  </Row>
                  <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Loyalty</label>
                    <Form.Item
                      name="loyality"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Switch onChange={onSwitchChange} defaultChecked/>&nbsp;{" "}
                      <span style={{ fontFamily: "Inter", fontSize: "1vw", fontWeight: 500 }}>Enable loyalty for this customer </span>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Program</label>
                    {enableLoyality && <Form.Item
                      name="program"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Select disabled= {true}>
                          <Option key={loyalityType[0]?.loyaltylevelId} value={loyalityType[0]?.loyaltylevelId}>{loyalityType[0]?.name}</Option>
                      </Select>
                    </Form.Item>}
                  </Col>
                </Row>
                  <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: "1.5vh" }}>
                    <Col span={24}>
                      <label style={{ fontSize: "1.2vh", fontWeight: 600, letterSpacing: "1.5px" }}>ADDITIONAL INFORMATION</label>
                    </Col>
                  </Row>
                  <Row >
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                      {/* <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date </label>
               <Form.Item name="anniversaryDate" rules={[{ required: true, message: "Please Input Anniversary Date" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                 <DatePicker placeholder="Anniversary Date" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
               </Form.Item> */}
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date</label>
                      <Form.Item name="anniversaryDate" disabled={true} style={{ border: "0" }}>
                        <DatePicker
                          disabled={true}
                          placeholder="DD/MM/YYYY"
                          className="cutomerDobInput"
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>

                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Tax ID</label>
                      <Form.Item name="taxID" disabled={true} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                        <Input disabled={true} className="customerDetailsInput" placeholder="Tax ID" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                      {/* <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date </label>
               <Form.Item name="anniversaryDate" rules={[{ required: true, message: "Please Input Anniversary Date" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                 <DatePicker placeholder="Anniversary Date" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
               </Form.Item> */}
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Birthday</label>
                      <Form.Item name="birthday" disabled={true} style={{ border: "0" }}>
                        <DatePicker
                          disabled={true}
                          placeholder="DD/MM/YYYY"
                          className="cutomerDobInput"
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>

                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Gender</label>
                      <Form.Item
                        name="gender"
                        disabled={true}
                        style={data.posScreenStyles.addEditCustomerModal.formItem}
                      >
                        <Input disabled={true} className="customerDetailsInput" placeholder="Enter Gender" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div style={{ backgroundColor: "#fff", padding: "2vh 2vw" }}>
                  <Row>
                    <p style={{ fontSize: "2.5vh", margin: 0, fontWeight: "600" }}>Additional Information</p>
                    <Col span={24}>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Last Visit Date
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.lastVisitDate ? cart?.customer?.lastVisitDate || "0" : selectedEditOldCustomer?.lastVisitDate || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          No Of Visits
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.noOfVisits ? cart?.customer?.noOfVisits || "0" : selectedEditOldCustomer?.noOfVisits || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Last Billing Amount
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.lastBillingAmount ? cart?.customer?.lastBillingAmount || "0" : selectedEditOldCustomer?.lastBillingAmount || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Average Basket Value
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.averageBasketValue ? cart?.customer?.averageBasketValue || "0" : selectedEditOldCustomer?.averageBasketValue || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Loyalty Balance Points
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.retlLoyaltyBalance ? cart?.customer?.retlLoyaltyBalance || " " : selectedEditOldCustomer?.retlLoyaltyBalance || " "}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Credit Balance
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.creditLimit ? cart?.customer?.creditLimit || " " : selectedEditOldCustomer?.creditLimit || " "}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  </div>
                </div>

              </Scrollbars>
            </Form>) : (
            <Form key={"editCustomer"} form={form} name="editCustomer" onFinish={editOldCustomer}>
              <Row style={{ paddingBottom: "2vh" }}>
                <Col span={24}>
                  <span style={data.posScreenStyles.addEditCustomerModal.addNewCustLabel}>Edit Customer</span>
                  <Button.Group>
                    <Button style={{ backgroundColor: !toggleCustomer ? '#2F3856' : '#fff', color: toggleCustomer ? '#929098' : '#fff', fontSize: "0.8vw", fontWeight: "400", height: "4vh", borderRadius: "3px", borderTopRightRadius: 0, borderBottomRightRadius: 0 }} onClick={() => setToggleCustomer(false)}>Contact</Button>
                    <Button style={{ backgroundColor: toggleCustomer ? '#2F3856' : '#fff', color: !toggleCustomer ? '#929098' : '#fff', fontSize: "0.8vw", fontWeight: "400", height: "4vh", borderRadius: "3px", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => setToggleCustomer(true)}>Details</Button>
                  </Button.Group>
                  <img
                    onClick={() => {
                      form.resetFields();
                      closeCustomerSearch();
                      setDisplayEditOldCustomer(false);
                      setEditFlag(false)
                    }}
                    style={{ float: "right", cursor: "pointer" }} src={closeX} alt="" />
                </Col>
              </Row>

              <Scrollbars
                thumbSize={100}
                // renderView={renderView}
                renderThumbHorizontal={renderThumbHorizontalScroll}
                renderThumbVertical={renderThumb}
                style={{ height: "37vh" }}>
                <Row>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>First Name</label>
                    <Form.Item name="editName" rules={[{ required: true, message: "Please Input Name" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Enter Name" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} readOnly />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Last Name</label>
                    <Form.Item
                      name="editLastName"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Enter Last Name" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} readOnly />
                    </Form.Item>
                  </Col>
                </Row>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Email</label>
                    <Form.Item
                      name="editEmail"
                      rules={[
                        {
                          required: false,
                          pattern: new RegExp(/\S+@\S+\.\S+/),
                          message: "Please Input Valid Email",
                        },
                      ]}
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Email" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} readOnly />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Contact Number</label>
                    <Form.Item
                      name="editMobile"
                      rules={[
                        {
                          required: false,
                          pattern: new RegExp(/\d+/g),
                          message: "Please Input Mobile",
                        },
                      ]}
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} readOnly />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Pincode</label>
                    <Form.Item
                      name="editPincode"
                      rules={[
                        {
                          required: false,
                          pattern: new RegExp(/\d+/g),
                          message: "Please Input Pincode",
                        },
                      ]}
                      style={data.posScreenStyles.addEditCustomerModal.formItem}

                    >
                      <Input className="customerDetailsInput" placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} readOnly />
                    </Form.Item>
                  </Col>
                </Row>
              </Scrollbars>
            </Form>
          )
        )}

        {/* <Form key={"editCustomer"} form={form} name="editCustomer" onFinish={editOldCustomer}>
            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Name</label>
                <Form.Item name="editName" rules={[{ required: true, message: "Please Input Name" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                  <Input placeholder="Enter Name" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Mobile Number</label>
                <Form.Item
                  name="editMobile"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp(/^[6-9][0-9]{9}$/),
                      message: "Please Input Valid Mobile Number",
                    },
                  ]}
                  style={data.posScreenStyles.addEditCustomerModal.formItem}
                >
                  <Input placeholder="Enter Mobile Number" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Email</label>
                <Form.Item
                  name="editEmail"
                  rules={[
                    {
                      required: false,
                      pattern: new RegExp(/\S+@\S+\.\S+/),
                      message: "Please Input Valid Email",
                    },
                  ]}
                  style={data.posScreenStyles.addEditCustomerModal.formItem}
                >
                  <Input placeholder="Enter Email" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Pincode</label>
                <Form.Item
                  name="editPincode"
                  rules={[
                    {
                      required: false,
                      pattern: new RegExp(/\d+/g),
                      message: "Please Input Pincode",
                    },
                  ]}
                  style={data.posScreenStyles.addEditCustomerModal.formItem}
                >
                  <Input placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={data.posScreenStyles.addEditCustomerModal.customerDetailsBtns}>
              <Button
                size="large"
                className="customerDetailsCancelBtn"
                style={data.posScreenStyles.addEditCustomerModal.customerDetailsCancelBtn}
                onClick={() => {
                  form.resetFields();
                  closeCustomerSearch();
                  setDisplayEditOldCustomer(false);
                }}
              >
                Cancel
              </Button>
              <Button size="large" className="customerDetailsSubmitBtnBtn" style={data.posScreenStyles.addEditCustomerModal.customerDetailsSubmitBtn} htmlType="submit">
                Submit
              </Button>
            </Row>
          </Form> */}
      </Modal>
      <Drawer
        closable={false}
        bodyStyle={{ padding: "0 24px" }}
        title={
          <div style={data.posScreenStyles.customerModal.customerDetailsCard}>
            <Row>
              <Col span={2} />
              <Col span={22}>
                {displayEditOldCustomer && editFlag ? (
                  <span style={data.posScreenStyles.addEditCustomerModal.addNewCustLabel}>Edit Customer</span>
                ) : (
                  <span style={data.posScreenStyles.addEditCustomerModal.addNewCustLabel}>View Customer</span>
                )}
                {/* {displayEditOldCustomer && editFlag ? ( */}
                <Button.Group>
                  <Button style={{ backgroundColor: !toggleCustomer ? '#2F3856' : '#fff', color: toggleCustomer ? '#929098' : '#fff', fontSize: "0.8vw", fontWeight: "400", height: "5vh", borderRadius: "3px", borderTopRightRadius: 0, borderBottomRightRadius: 0 }} onClick={() => setToggleCustomer(false)}>Contact</Button>
                  <Button style={{ backgroundColor: toggleCustomer ? '#2F3856' : '#fff', color: !toggleCustomer ? '#929098' : '#fff', fontSize: "0.8vw", fontWeight: "400", height: "5vh", borderRadius: "3px", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => setToggleCustomer(true)}>Details</Button>
                </Button.Group>
                <img
                  onClick={() => {
                    form.resetFields();
                    closeCustomerSearch();
                    setDisplayEditOldCustomer(false);
                    setEditFlag(false);
                    setToggleCustomer(false);
                  }}
                  style={{ float: "right", cursor: "pointer", marginTop: "-4vh", marginRight: "-1.5vw", width:"1.5vw" }} src={closeX} alt="" />
              </Col>
            </Row>
          </div>
        }
        className="addCustomer"
        placement="bottom"
        visible={displayEditOldCustomer}
        onClose={() => {
          form.resetFields();
          closeCustomerSearch();
          setDisplayEditOldCustomer(false);
          setEditFlag(false);

        }}
        height="87vh"
      >
        {displayEditOldCustomer && editFlag ? (
          <Form key={"editCustomer"} style={{ padding: "0 5%" }} form={form} name="editCustomer" >
            <Scrollbars
              thumbSize={100}
              // renderView={renderView}
              renderThumbHorizontal={renderThumbHorizontalScroll}
              renderThumbVertical={renderThumb}
              style={{ height: "57vh" }}>
              <div style={{ display: toggleCustomer ? "block" : "none" }}>

              <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.35)", marginBottom: "1.5vh", margin: '0 2vw', marginBottom: "1vh", width:"80.5vw" }}>
                  <Col span={24}>
                    <label style={{ fontSize: "1.8vh", fontWeight: 600, letterSpacing: "1.5px" }}>MAILING ADDRESS</label>
                  </Col>
                </Row>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Street</label>
                    <Form.Item name="street"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Street" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>City</label>
                    <Form.Item name="city" 
                      style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="City" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop:"4vh"  }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>State</label>
                    <Form.Item
                      name="state"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input readOnly className="customerDetailsInput" placeholder="Enter State" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop:"4vh"  }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Country</label>
                    <Form.Item
                      name="country"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input readOnly className="customerDetailsInput" placeholder="Enter Country" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop:"4vh"  }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Pincode</label>
                    <Form.Item
                      name="pincode"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: "1.5vh", margin: '0 2vw', marginBottom: "1vh", width:"80.5vw", marginTop:"3vh",width:"80.5vw"  }}>
                  <Col span={24}>
                    <label style={{ fontSize: "1.8vh", fontWeight: 600, letterSpacing: "1.5px" }}>LOYALTY</label>
                  </Col>
                </Row>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Loyalty</label>
                    <Form.Item
                      name="loyality"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Switch onChange={onSwitchChange} defaultChecked/>&nbsp;{" "}
                      <span style={{ fontFamily: "Inter", fontSize: "1vw", fontWeight: 500 }}>Enable loyalty for this customer </span>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Program</label>
                    {enableLoyality && <Form.Item
                      name="program"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Select disabled= {true}>
                          <Option key={loyalityType[0]?.loyaltylevelId} value={loyalityType[0]?.loyaltylevelId}>{loyalityType[0]?.name}</Option>
                      </Select>
                    </Form.Item>}
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: "1.5vh", margin: '0 2vw', marginBottom: "1vh", width:"80.5vw" }}>
                  <Col span={24}>
                    <label style={{ fontSize: "1.8vh", fontWeight: 600, letterSpacing: "1.5px" }}>GENERAL INFORMATION</label>
                  </Col>
                </Row>
                <Row >

                  {/* <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15 }}>

                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date</label>
                    <Form.Item name="AnniversaryDate" style={{ border: "0" }}>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        className="cutomerAniversaryDate"
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>

                  </Col> */}
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 20, marginTop: "2.5vh" }}>
                  <label style={{fontSize:"1.2vw", marginTop:"10vh", color:"#0F0718", fontWeight:"500", marginLeft:"0.5vw"}}>Anniversary Date</label>
                    <Form.Item name="anniversaryDate" style={{ border: "0" }}>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        className="cutomerDobInput"
                        format="DD/MM/YYYY"
                        style={{ height: '7.5vh', border: 'none', marginTop: 10, width: '95%', marginLeft:"0.4vw" }}
                      />
                    </Form.Item>

                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Tax ID</label>
                    <Form.Item name="taxID"
                      // rules={[{ required: true, message: "Please Input Tax ID" }]} 
                      style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Tax ID" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw" }}>
                    {/* <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date </label>
               <Form.Item name="anniversaryDate" rules={[{ required: true, message: "Please Input Anniversary Date" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                 <DatePicker placeholder="Anniversary Date" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
               </Form.Item> */}
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Date of Birth</label>
                    <Form.Item name="birthday"
                      // rules={[{ required: true, message: 'Please Input Birthday Date' }]}
                      style={{ border: "0", }}>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        className="cutomerDobInput"
                        format="DD/MM/YYYY"
                        style={{ height: '7.5vh', border: 'none', marginTop: 10, width: '100%' }}
                      />
                    </Form.Item>

                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Gender</label>
                    <Form.Item
                      name="gender"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}

                    >
                      <Select size="large" className="genderSelect" >
                        <Option key={"MAL"} value="MAL"  >
                          Male
                        </Option>
                        <Option key={"FEM"} value="FEM">
                          Female
                        </Option>
                        <Option key={"TRA"} value="TRA">
                          Transgender
                        </Option>

                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <div style={{ backgroundColor: "#fff", padding: "2vh 2vw", margin: '0 2vw', width:"80.7vw" }}>
                  <Row>
                    <p style={{ fontSize: "2.5vh", margin: 0, fontWeight: "600" }}>Additional Information</p>
                    <Col span={24}>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Last Visit Date
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.lastVisitDate ? cart?.customer?.lastVisitDate || "0" : selectedEditOldCustomer?.lastVisitDate || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          No Of Visits
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.noOfVisits ? cart?.customer?.noOfVisits || "0" : selectedEditOldCustomer?.noOfVisits || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Last Billing Amount
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.lastBillingAmount ? cart?.customer?.lastBillingAmount || "0" : selectedEditOldCustomer?.lastBillingAmount || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Average Basket Value
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.averageBasketValue ? cart?.customer?.averageBasketValue || "0" : selectedEditOldCustomer?.averageBasketValue || "0"}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Loyalty Balance Points
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.retlLoyaltyBalance ? cart?.customer?.retlLoyaltyBalance.toFixed(2) || " " : selectedEditOldCustomer?.retlLoyaltyBalance || " "}
                        </Col>
                      </Row>
                      <Row style={{ padding: "3px 0" }}>
                        <Col span={12} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          Credit Balance
                        </Col>
                        <Col span={2}>
                          :
                        </Col>
                        <Col span={9} offset={1} style={{ fontSize: "1.8vh", fontWeight: "500", color: "#0F0718" }}>
                          {cart?.customer?.creditLimit ? cart?.customer?.creditLimit || " " : selectedEditOldCustomer?.creditLimit || " "}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                </div>
              </div>
              <div style={{ display: toggleCustomer ? "none" : "block", padding: "1vw" }}>
                <Row>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15, marginTop:"1.6vh"  }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>First Name</label>
                    <Form.Item name="editName" rules={[{ required: true, message: "Please Input Name" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Enter Name" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15, marginTop:"1.6vh"  }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Last Name</label>
                    <Form.Item
                      name="lastName"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input className="customerDetailsInput" placeholder="Enter Last Name" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15,marginTop:"4vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Email</label>
                    <Form.Item
                      name="editEmail"
                      // rules={[
                      //   {
                      //     required: false,
                      //     pattern: new RegExp(/\S+@\S+\.\S+/),
                      //     message: "Please Input Valid Email",
                      //   },
                      // ]}
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Email" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingLeft: 15,marginTop:"4vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Contact Number</label>
                    <Form.Item
                      name="editMobile"
                      rules={[
                        {
                          required: false,
                          pattern: new RegExp(/\d+/g),
                          message: "Please Input Mobile",
                        },
                      ]}
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Contact Number" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ paddingRight: 15,marginTop:"4vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Pincode</label>
                    <Form.Item
                      name="editPincode"
                      // rules={[
                      //   {
                      //     required: false,
                      //     pattern: new RegExp(/\d+/g),
                      //     message: "Please Input Pincode",
                      //   },
                      // ]}
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input className="customerDetailsInput" placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Scrollbars>
            <Row>
              <Col xs={{ span: 9 }} lg={{ span: 19 }} xl={{ span: 19 }} xxl={{ span: 19 }} />
              <Col xs={{ span: 14 }} lg={{ span: 4 }} xl={{ span: 4 }} xxl={{ span: 4 }} >
                <Form.Item
                  style={{ paddingTop: "3vh" }}
                >
                  <Button className="customerDetailsSubmitBtnBtn" style={data.posScreenStyles.addEditCustomerModal.customerDetailsSubmitBtn} onClick={editCustomer}>
                    Save & Proceed
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          toggleCustomer ? (
            <Form key={"editCustomer"} style={{ padding: "0 5%" }} form={form} name="editCustomer" >
              <Scrollbars
                thumbSize={100}
                // renderView={renderView}
                renderThumbHorizontal={renderThumbHorizontalScroll}
                renderThumbVertical={renderThumb}
                style={{ height: "57vh" }}>
                <div style={{ display: toggleCustomer ? "block" : "none" }}>
                <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.35)", marginBottom: "1.5vh", margin: '0 2vw', marginBottom: "1vh", width:"80.5vw" }}>
                    <Col span={24} >
                      <label style={{ fontSize: "1.8vh", fontWeight: 600, letterSpacing: "1.5px" }}>MAILING ADDRESS</label>
                    </Col>
                  </Row>
                  <Row >
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Street</label>
                      <Form.Item name="street" disabled={true} rules={[{ required: true, message: "Please Input Street" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                        <Input disabled={true} className="customerDetailsInput" placeholder="Street" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} value={selectedEditOldCustomer ? selectedEditOldCustomer?.customerAddress?.line1 : cart?.customer?.customerAddress?.line1} />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>City</label>
                      <Form.Item name="city" disabled={true} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                        <Input disabled={true} className="customerDetailsInput" placeholder="City" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} value={selectedEditOldCustomer ? selectedEditOldCustomer?.customerAddress?.city : cart?.customer?.customerAddress?.city} readOnly />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw",marginTop: "4vh" }}>
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>State</label>
                      <Form.Item
                        name="state"
                        disabled={true}
                        style={data.posScreenStyles.addEditCustomerModal.formItem}
                      >
                        <Input disabled={true} className="customerDetailsInput" placeholder="Enter State" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} value={selectedEditOldCustomer ? selectedEditOldCustomer?.customerAddress?.line2 : cart?.customer?.customerAddress?.region} readOnly />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw",marginTop: "4vh" }}>
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Country</label>
                      <Form.Item
                        name="country"
                        disabled={true}
                        style={data.posScreenStyles.addEditCustomerModal.formItem}
                      >
                        <Input disabled={true} className="customerDetailsInput" placeholder="Enter Country" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} value={selectedEditOldCustomer ? selectedEditOldCustomer?.customerAddress?.country : cart?.customer?.customerAddress?.country} readOnly />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw",marginTop: "4vh" }}>
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Pincode</label>
                      <Form.Item
                        name="pincode"
                        disabled={true}
                        style={data.posScreenStyles.addEditCustomerModal.formItem}
                      >
                        <Input disabled={true} className="customerDetailsInput" placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} value={selectedEditOldCustomer ? selectedEditOldCustomer?.pincode : cart?.customer?.pincode} readOnly />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: "1.5vh", margin: "0 2vw", marginBottom: "1vh", marginTop:"3vh", width:"80.5vw" }}>
                    <Col span={24}>
                      <label style={{ fontSize: "1.8vh", fontWeight: 600, letterSpacing: "1.5px" }}>LOYALTY</label>
                    </Col>
                  </Row>
                  <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Loyalty</label>
                    <Form.Item
                      name="loyality"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Switch onChange={onSwitchChange} defaultChecked/>&nbsp;{" "}
                      <span style={{ fontFamily: "Inter", fontSize: "1vw", fontWeight: 500 }}>Enable loyalty for this customer </span>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Program</label>
                    {enableLoyality && <Form.Item
                      name="program"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Select disabled= {true}>
                          <Option key={loyalityType[0]?.loyaltylevelId} value={loyalityType[0]?.loyaltylevelId}>{loyalityType[0]?.name}</Option>
                      </Select>
                    </Form.Item>}
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: "1.5vh", margin: "0 2vw", marginBottom: "1vh", width:"80.5vw" }}>
                    <Col span={24}>
                      <label style={{ fontSize: "1.8vh", fontWeight: 600, letterSpacing: "1.5px" }}>GENERAL INFORMATION</label>
                    </Col>
                  </Row>
                  <Row >
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                      {/* <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date </label>
               <Form.Item name="anniversaryDate" rules={[{ required: true, message: "Please Input Anniversary Date" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                 <DatePicker placeholder="Anniversary Date" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
               </Form.Item> */}
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date</label>
                      <Form.Item name="anniversaryDate"
                        disabled={true} style={{ border: "0" }}>
                        <DatePicker
                          disabled={true}
                          placeholder="DD/MM/YYYY"
                          className="cutomerDobInput"
                          format="DD/MM/YYYY"
                          style={{height:"7.3vh", border:"none", marginTop:10, width:"95%"}}
                        />

                      </Form.Item>

                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Tax ID</label>
                      <Form.Item disabled={true} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                        <Input disabled={true} className="customerDetailsInput" placeholder="Tax ID" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} value={selectedEditOldCustomer ? selectedEditOldCustomer?.taxId : cart?.customer?.taxId} readOnly />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop:"2vh"  }}>
                      {/* <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Anniversary Date </label>
               <Form.Item name="anniversaryDate" rules={[{ required: true, message: "Please Input Anniversary Date" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                 <DatePicker placeholder="Anniversary Date" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
               </Form.Item> */}
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Birthday</label>
                      <Form.Item name="birthday"
                        disabled={true} style={{ border: "0" }}>
                        <DatePicker
                          disabled={true}
                          placeholder="DD/MM/YYYY"
                          className="cutomerDobInput"
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>

                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw",marginTop:"2vh"  }}>
                      <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Gender</label>
                      <Form.Item
                        name="gender"
                        disabled={true}
                        style={data.posScreenStyles.addEditCustomerModal.formItem}

                      >
                        <Select size="large" className="genderSelect" >
                          <Option key={"MAL"} value="MAL"  >
                            Male
                          </Option>
                          <Option key={"FEM"} value="FEM">
                            Female
                          </Option>
                          <Option key={"TRA"} value="TRA">
                            Transgender
                          </Option>

                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <div style={{ backgroundColor: "#fff", padding: "2vh 2vw", margin: '0 2vw', marginTop:"2vh", width:"80.6vw" }}>
                    <Row>
                      <p style={{ fontSize: "2.5vh", margin: 0, fontWeight: "600" }}>Additional Information</p>
                      <Col span={24}>
                        <Row style={{ padding: "3px 0" }}>
                          <Col span={12} style={{ fontSize: "2vh", fontWeight: "500", color: "#0F0718" }}>
                            Last Visit Date
                          </Col>
                          <Col span={2}>
                            :
                          </Col>
                          <Col span={9} offset={1} style={{ fontSize: "2vh", fontWeight: "500", color: "#0F0718" }}>
                            {selectedEditOldCustomer ? selectedEditOldCustomer.lastVisitDate : cart?.customer?.lastVisitDate}
                          </Col>
                        </Row>
                        <Row style={{ padding: "3px 0" }}>
                          <Col span={12} style={{ fontSize: "2vh", fontWeight: "500", color: "#0F0718" }}>
                            No Of Visits
                          </Col>
                          <Col span={2}>
                            :
                          </Col>
                          <Col span={9} offset={1} style={{ fontSize: "2vh", fontWeight: "500", color: "#0F0718" }}>
                            {selectedEditOldCustomer ? selectedEditOldCustomer.noOfVisits : cart?.customer?.noOfVisits}
                          </Col>
                        </Row>
                        <Row style={{ padding: "3px 0" }}>
                          <Col span={12} style={{ fontSize: "2vh", fontWeight: "500", color: "#0F0718" }}>
                            Last Billing Amount
                          </Col>
                          <Col span={2}>
                            :
                          </Col>
                          <Col span={9} offset={1} style={{ fontSize: "2vh", fontWeight: "500", color: "#0F0718" }}>
                            {selectedEditOldCustomer ? selectedEditOldCustomer.lastBillingAmount : cart?.customer.lastBillingAmount}
                          </Col>
                        </Row>
                        <Row style={{ padding: "3px 0" }}>
                          <Col span={12} style={{ fontSize: "2vh", fontWeight: "500", color: "#0F0718" }}>
                            Average Basket Value
                          </Col>
                          <Col span={2}>
                            :
                          </Col>
                          <Col span={9} offset={1} style={{ fontSize: "2vh", fontWeight: "500", color: "#0F0718" }}>
                            {selectedEditOldCustomer ? selectedEditOldCustomer.averageBasketValue : cart?.customer?.averageBasketValue}
                          </Col>
                        </Row>
                        <Row style={{ padding: "3px 0" }}>
                          <Col span={12} style={{ fontSize: "2vh", fontWeight: "500", color: "#0F0718" }}>
                            Loyalty Balance Points
                          </Col>
                          <Col span={2}>
                            :
                          </Col>
                          <Col span={9} offset={1} style={{ fontSize: "2vh", fontWeight: "500", color: "#0F0718" }}>
                            {cart?.customer?.retlLoyaltyBalance ? cart?.customer?.retlLoyaltyBalance || " " : selectedEditOldCustomer?.retlLoyaltyBalance || " "}
                          </Col>
                        </Row>
                        <Row style={{ padding: "3px 0" }}>
                          <Col span={12} style={{ fontSize: "2vh", fontWeight: "500", color: "#0F0718" }}>
                            Credit Balance
                          </Col>
                          <Col span={2}>
                            :
                          </Col>
                          <Col span={9} offset={1} style={{ fontSize: "2vh", fontWeight: "500", color: "#0F0718" }}>
                            {cart?.customer?.creditLimit ? cart?.customer?.creditLimit || " " : cart?.customer?.creditLimit || " "}
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                  </div>
                </div>

              </Scrollbars>
            </Form>) : (
            <Form key={"viewCustomer"} style={{ padding: "0 5%" }} form={form} name="viewFo">
              <Scrollbars
                thumbSize={100}
                // renderView={renderView}
                renderThumbHorizontal={renderThumbHorizontalScroll}
                renderThumbVertical={renderThumb}
                style={{ height: "57vh" }}>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop:"2vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>First Name</label>
                    <Form.Item disabled={true} name="editName" rules={[{ message: "Please Input Name" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input disabled={true} className="customerDetailsInput" placeholder="Enter Name" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} value={selectedEditOldCustomer ? selectedEditOldCustomer?.name : cart?.customer?.name} readOnly />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop:"2vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Last Name</label>
                    <Form.Item
                      disabled={true}
                      name="lastName"
                      style={data.posScreenStyles.addEditCustomerModal.formItem}>
                      <Input disabled={true} className="customerDetailsInput" placeholder="Enter Last Name" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} value={cart?.customer?.lastName ? cart?.customer?.lastName : selectedEditOldCustomer?.lastName} readOnly />
                    </Form.Item>
                  </Col>
                </Row>
                <Row >
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop:"4vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Email</label>
                    <Form.Item
                      disabled={true}
                      name="editEmail"
                      rules={[
                        {
                          required: false,
                          pattern: new RegExp(/\S+@\S+\.\S+/),
                          message: "Please Input Valid Email",
                        },
                      ]}
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input disabled={true} className="customerDetailsInput" placeholder="Enter Email" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} value={""} readOnly />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop:"4vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Contact Number</label>
                    <Form.Item
                      disabled={true}
                      name="editMobile"
                      rules={[
                        {
                          required: false,
                          pattern: new RegExp(/^[6-9][0-9]{9}$/),
                          message: "Please Input Mobile",
                        },
                      ]}
                      style={data.posScreenStyles.addEditCustomerModal.formItem}
                    >
                      <Input disabled={true} className="customerDetailsInput" placeholder="Enter Contact Number" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} value={selectedEditOldCustomer ? selectedEditOldCustomer?.mobileNo : cart?.customer?.mobileNo} readOnly />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ padding: "0 2vw", marginTop:"4vh" }}>
                    <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Pincode</label>
                    <Form.Item
                      disabled={true}
                      name="editPincode"
                      rules={[
                        {
                          required: false,
                          pattern: new RegExp(/\d+/g),
                          message: "Please Input Pincode",
                        },
                      ]}
                      style={data.posScreenStyles.addEditCustomerModal.formItem}

                    >
                      <Input disabled={true} className="customerDetailsInput" placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} value={selectedEditOldCustomer ? selectedEditOldCustomer?.pincode : cart?.customer?.pincode} readOnly />
                    </Form.Item>
                  </Col>
                </Row>
              </Scrollbars>
            </Form>
          )
        )}
      </Drawer>
      {/* EDIT CUSTOMER MODAL END*/}

      {/* PAYMENT MODAL BEGIN*/}
      <Modal visible={paymentModal} keyboard={false} closable={false} footer={null} width="60%" centered bodyStyle={{ padding: 0 }}>
        <Card bodyStyle={{ padding: 15 }}>
          <Row gutter={[10, 0]}>
            <Col span={12}>
              <Card style={data.posScreenStyles.restaurantPaymentModal.noBorderCard} bodyStyle={{ padding: "10px" }}>
                <div style={tillData.tillAccess.cwrTill.layAway === "Y" && tillData.tillAccess.cwrTill.payNow === "N" ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                  {/*  <p className="para-margin" /> */}
                  <Row>
                    <Col span={24}>
                      <Scrollbars style={data.posScreenStyles.restaurantPaymentModal.scrollValue}>
                        {_.sortBy(tillDataPaymentMethods, "sequenceNo").map((pm, ind) => {
                          return posConfig.loyaltyApplicable === "N" && pm.name === "Loyalty" ? null : (
                            <Card
                              key={pm.finPaymentmethodId}
                              onClick={() => {
                                if (cart.items.length > 0) {
                                  setSelectedPaymentMethod(pm);
                                  openPaymentModalByCustomer();
                                }
                              }}
                              ref={ind === 0 ? paymentCardRef : null}
                              style={data.posScreenStyles.restaurantPaymentModal.noBorderCard}
                              tabIndex={0}
                              onKeyPress={(e) => (e.charCode === 13 ? setSelectedPaymentMethod(pm) : null)}
                              bodyStyle={{ padding: "0px", marginTop: "15px" }}
                            >
                              <Row>
                                <Col span={24}>
                                  <Card
                                    style={
                                      pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId
                                        ? data.posScreenStyles.restaurantPaymentModal.selectedPaymentType
                                        : data.posScreenStyles.restaurantPaymentModal.paymtCard
                                    }
                                    // className={
                                    //   pm.finPaymentmethodId ===
                                    //   selectedPaymentMethod.finPaymentmethodId
                                    //     ? "selectedPaymentType"
                                    //     : "paymt-card"
                                    // }
                                    bodyStyle={{ padding: "8px 0px" }}
                                  >
                                    <Row>
                                      <Col span={14} >
                                        <div style={{ display: "flex" }}>
                                          <img
                                            src={pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? CashLogoWhite : CashLogo}
                                            style={data.posScreenStyles.restaurantPaymentModal.wfifty}
                                            alt=""
                                          />{" "}
                                          <p
                                            style={
                                              pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId
                                                ? data.posScreenStyles.restaurantPaymentModal.selectedPaymentTypeName
                                                : data.posScreenStyles.restaurantPaymentModal.paymtName
                                            }
                                          >
                                            {pm.name}
                                          </p>
                                        </div>
                                        {cart.printedName && pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? <p style={{ color: "white", margin: 0 }}>{cart.printedName} - {currenciesList[0].symbolRightSide === "N" && pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? currenciesList[0].currSymbol : ""} {cart.fee}</p> : null}
                                      </Col>

                                      <Col span={10} style={{ alignSelf: "center" }}>
                                        {(() => {
                                          const iN = cart.payments.findIndex((paymentMade) => paymentMade.name === pm.name);
                                          if (iN >= 0) {
                                            return (
                                              <Row>
                                                <Col span={20}>
                                                  <span
                                                    style={
                                                      pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId
                                                        ? data.posScreenStyles.restaurantPaymentModal.selectedPaymentTypeName
                                                        : data.posScreenStyles.restaurantPaymentModal.paymtName
                                                    }
                                                  >
                                                    {currenciesList[0]?.symbolRightSide === "N" ? pm.currSymbol : ""} {parseFloat(cart.payments[iN].amount).toFixed(2)} {currenciesList[0].symbolRightSide === "Y" ? pm.currSymbol : ""}
                                                  </span>
                                                </Col>
                                                <Col span={4}>
                                                  <span style={data.posScreenStyles.restaurantPaymentModal.deleteIconSpan}>
                                                    <img
                                                      src={Delete}
                                                      onClick={() => requestPayment(cart.payments[iN], -cart.payments[iN].amount, "allow")}
                                                      style={data.posScreenStyles.restaurantPaymentModal.deleteIcon}
                                                      alt=""
                                                    />
                                                  </span>
                                                </Col>
                                              </Row>
                                            );
                                          }
                                        })()}
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                              </Row>
                            </Card>
                          );
                        })}
                      </Scrollbars>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 10 }}>
                    <Button
                      type="dashed"
                      size="large"
                      disabled={cart.mPricingCouponId ? true : false}
                      onClick={() => setCouponModalVisible(true)}
                      className="add-payment-btn-white"
                      style={data.posScreenStyles.restaurantPaymentModal.addpaymentbtnWhite}
                    >
                      <TagsOutlined /> Apply Coupon
                    </Button>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                style={data.posScreenStyles.restaurantPaymentModal.noBorderCard}
                bodyStyle={{
                  padding: "10px",
                  /*  backgroundColor: "#F5F5F5",
                    borderRadius: "0px 0px 6px 6px", */
                }}
              >
                <Row style={{ marginBottom: 10 }}>
                  <Col span={12}>
                    {posConfig.loyaltyApplicable === "Y" && (
                      <span style={data.posScreenStyles.restaurantPaymentModal.topayEntrAmt}>
                        Loyalty Bal:&nbsp;
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#030d1c",
                            fontSize: "1.5vw",
                          }}
                        >
                          {cart?.customer?.retlLoyaltyBalance || 0}
                        </span>
                      </span>
                    )}
                  </Col>
                  <Col span={12}>
                    <span style={data.posScreenStyles.restaurantPaymentModal.deleteIconSpan}>
                      <img src={closeIcon} alt="close" onClick={closePaymentModal} />
                    </span>
                  </Col>
                </Row>
                {/* <Row>
                  <Col offset={12} span={12}>
                    <br />
                  </Col>
                </Row> */}
                <Row>
                  <Col span={12}>
                    <span style={data.posScreenStyles.restaurantPaymentModal.cartTotalMethod}>{parseFloat(cart.total) >= parseFloat(cart.paid) ? "To Pay" : "Change"}</span>
                  </Col>
                  <Col span={12}>
                    <span style={data.posScreenStyles.restaurantPaymentModal.cartTotalValue}>
                      {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                      &nbsp;
                      {parseFloat(cart.total) >= parseFloat(cart.paid) ? cart.convertionTotal !== undefined ? cart.convertionTotal : cart.total :
                        parseFloat(cart.changedValue).toFixed(2)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                    </span>
                  </Col>
                </Row>
              </Card>
              <Card style={data.posScreenStyles.restaurantPaymentModal.noBorderCard} bodyStyle={{ padding: "10px" }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <h3 style={data.posScreenStyles.restaurantPaymentModal.topayEntrAmt}>{paymentModalLoyalityMessages.inputMessage}</h3>
                  </Col>
                  {/* <p className="para-margin" /> */}
                  {/* <Row> */}
                  <Col span={24}>
                    <Input
                      ref={paymentModalInputRef}
                      value={amount}
                      onChange={onChangeAmount}
                      disabled={tillData.tillAccess.cwrTill.layAway === "Y" && tillData.tillAccess.cwrTill.payNow === "N" ? true : cart.total < cart.paid ? true : false}
                      onPressEnter={parseFloat(cart.total) >= parseFloat(cart.paid) ? () => requestPayment(selectedPaymentMethod, amount) : () => processOrder()}
                      style={data.posScreenStyles.restaurantPaymentModal.topayInput}
                      size="large"
                    />
                  </Col>
                  {/* </Row> */}
                  {/* <p className="para-margin" /> */}
                  {/* <Row style={{ paddingTop: 10 }}> */}
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("1")}>
                        1
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("2")}>
                        2
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("3")}>
                        3
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("x")}>
                        <img src={Backspace} alt="back space" width={width <= 1024 ? 20 : 30} height={width <= 1024 ? 15 : 20} />
                      </button>
                    </AspectRatio>
                  </Col>
                  {/* </Row> */}
                  {/* <Row style={{ paddingTop: 15 }}> */}
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("4")}>
                        4
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("5")}>
                        5
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("6")}>
                        6
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount(".")}>
                        .
                      </button>
                    </AspectRatio>
                  </Col>
                  {/* </Row> */}
                  {/* <Row style={{ paddingTop: 15 }}> */}
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("7")}>
                        7
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("8")}>
                        8
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("9")}>
                        9
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("0")}>
                        0
                      </button>
                    </AspectRatio>
                  </Col>
                  {/* </Row> */}
                  {/* <p className="para-margin" /> */}
                  {/* <Row style={{ paddingTop: 18 }}> */}
                  {tillData.tillAccess.cwrTill.payNow === "Y" ? (
                    <Button
                      size="large"
                      onClick={parseFloat(cart.total) >= parseFloat(cart.paid) ? () => requestPayment(selectedPaymentMethod, amount) : () => processOrder()}
                      style={data.posScreenStyles.restaurantPaymentModal.addPaymentBtn}
                    >
                      {cart.items.length !== 0 ? (parseFloat(cart.total) > parseFloat(cart.paid) ? paymentModalLoyalityMessages.buttonText : cart.payments.length > 0 ? "Order" : paymentModalLoyalityMessages.buttonText) : "Payment Not Allowed !"}
                    </Button>
                  ) : null}
                  {tillData.tillAccess.cwrTill.layAway === "Y" ? (
                    <Button
                      size="large"
                      onClick={() => processOrder("layaway")}
                      style={{ ...data.posScreenStyles.restaurantPaymentModal.addPaymentBtn, backgroundColor: "#708090", border: "1px solid #708090" }}
                    >
                      {cart.items.length !== 0 ? "Layaway" : "Operation Not Allowed !"}
                    </Button>
                  ) : null}
                </Row>
              </Card>
            </Col>
          </Row>
        </Card>
      </Modal>
      {/* PAYMENT MODAL END*/}

      {/* ORDER HISTORY MODAL BEGIN*/}
      <Modal
        // visible={displayOrderHistory}
        closable={false}
        onCancel={() => setDisplayOrderHistory(false)}
        footer={null}
        // width="52%"
        bodyStyle={{ padding: "20px 15px 15px" }}
      >
        <Row gutter={[0, 8]}>
          <Col span={8}>
            <ArrowLeftOutlined style={data.posScreenStyles.orderHistoryModal.backArrowIcon} onClick={() => setDisplayOrderHistory(false)} />
          </Col>
          <Col span={16}>
            <p style={data.posScreenStyles.orderHistoryModal.orderHistoryModalTitle}>Order History</p>
          </Col>
        </Row>
        <Row gutter={[8, 0]}>
          <Col span={10}>
            <Select
              defaultValue="orderDocumentNo"
              suffixIcon={<CaretDownOutlined />}
              placeholder="Document"
              style={data.posScreenStyles.orderHistoryModal.orderHistoryAttribute}
              size="large"
              onChange={changeOrderHistorySearchType}
            >
              <Option value="orderDocumentNo">Document</Option>
              <Option value="orderCustomerSearchKey">Customer SearchKey</Option>
            </Select>
          </Col>
          <Col span={14}>
            <Input
              ref={orderHistorySearchInputRef}
              placeholder="Search"
              size="large"
              allowClear={false}
              style={data.posScreenStyles.orderHistoryModal.orderHistorySearchInput}
              onPressEnter={searchOrderHistory}
              value={orderHistoryInput}
              onChange={(e) => {
                e.target.value === "" ? showOrderHistory() : setOrderHistoryInput(e.target.value);
              }}
              suffix={<SearchOutlined onClick={searchOrderHistory} style={data.posScreenStyles.customerModal.searchIcon} />}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Tabs defaultActiveKey="1" centered style={data.posScreenStyles.orderHistoryModal.orderHistoryTabs}>
            <TabPane tab="All" key="1">
              <Row gutter={[8, 8]} style={{ margin: "0px" }}>
                <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
                  <Scrollbars className="orderHistoryScroll">
                    {orderHistoryDetails.map((item) => (
                      <Card
                        tabIndex={0}
                        onKeyDown={(e) => (e.keyCode === 13 ? showOrderHistoryLine(item.sOrderID) : null)}
                        key={item.documentno}
                        style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard}
                        bodyStyle={{ padding: 10 }}
                      >
                        <Row>
                          <Col span={11}>
                            <p style={data.posScreenStyles.orderHistoryModal.orderDate}>{item.orderTime}</p>
                          </Col>
                          <Col span={11}>
                            <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>{item.cwrB2cCustomer?.name}</p>
                          </Col>
                          <Col span={2}>
                            <img
                              style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}
                              src={DropdownCircle}
                              alt=""
                              width={15}
                              height={15}
                              onClick={() => showOrderHistoryLine(item.sOrderID)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12}>
                            <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.documentno}</p>
                          </Col>
                          <Col span={10}>
                            <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>{item.isSynced === 1 ? "Success" : "Pending"}</p>
                          </Col>
                          <Col span={2}></Col>
                        </Row>
                        {item.sOrderID === selectedOrderHistoryLine ? (
                          <Row>
                            <table style={data.posScreenStyles.orderHistoryModal.orderDetailsTable}>
                              <tr style={data.posScreenStyles.orderHistoryModal.orderDetailsTableHeader}>
                                <th>Description</th>
                                <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Qty</th>
                                <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Unit Price</th>
                                <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Net Total</th>
                              </tr>
                              {item.items.map((subItem, index) => (
                                <tr key={`${subItem.name}-${index}`} style={data.posScreenStyles.orderHistoryModal.orderDetailsData}>
                                  <td style={data.posScreenStyles.orderHistoryModal.orderDetailsDesc}>{subItem.name}</td>
                                  <td>{subItem.weight}</td>
                                  <td>{parseFloat(subItem.salePrice).toFixed(2)}</td>
                                  <td>{parseFloat(subItem.nettotal).toFixed(2)}</td>
                                </tr>
                              ))}
                            </table>
                            <Divider />
                            <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsTotalTaxes}>
                              <Col span={12}>
                                <p>Taxes</p>
                              </Col>
                              <Col span={12}>
                                <p style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}>{parseFloat(item.tax).toFixed(2)}</p>
                              </Col>
                            </Row>
                            <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsTotalTaxes}>
                              <Col span={12}>
                                <p>Total</p>
                              </Col>
                              <Col span={12}>
                                <p style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}>{parseFloat(item.total).toFixed(2)}</p>
                              </Col>
                            </Row>
                            <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsButtons}>
                              <Col span={24}>
                                <Button
                                  size="large"
                                  style={data.posScreenStyles.orderHistoryModal.rePrintBtn}
                                  onClick={() => (isPrintModeXML ? xmlPrint(item, "Duplicate Copy") : htmlPrint(item, "Duplicate Copy"))}
                                >
                                  Print
                                </Button>
                              </Col>
                              {/* <Col span={12}>
                                <Button size="large" style={data.posScreenStyles.orderHistoryModal.returnBtn}>
                                  Return
                                </Button>
                              </Col> */}
                            </Row>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Card>
                    ))}
                  </Scrollbars>
                </Card>
              </Row>
            </TabPane>
            <TabPane tab="Success" key="2">
              <Row gutter={[8, 8]} style={{ margin: "0px" }}>
                <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
                  <Scrollbars className="orderHistoryScroll">
                    {orderHistoryDetails.map((item) =>
                      item.isSynced === 1 ? (
                        <Card key={item.documentno} style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard} bodyStyle={{ padding: 10 }}>
                          <Row>
                            <Col span={11}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderDate}>{item.orderTime}</p>
                            </Col>
                            <Col span={11}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>{item.customer.name}</p>
                            </Col>
                            <Col span={2}>
                              <img
                                style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}
                                src={DropdownCircle}
                                alt=""
                                width={15}
                                height={15}
                                onClick={() => showOrderHistoryLine(item.sOrderID)}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={12}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.documentno}</p>
                            </Col>
                            <Col span={10}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>{item.isSynced === 1 ? "Success" : "Pending"}</p>
                            </Col>
                            <Col span={2}></Col>
                          </Row>
                          {item.sOrderID === selectedOrderHistoryLine ? (
                            <Row>
                              <table style={data.posScreenStyles.orderHistoryModal.orderDetailsTable}>
                                <tr style={data.posScreenStyles.orderHistoryModal.orderDetailsTableHeader}>
                                  <th>Description</th>
                                  <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Qty</th>
                                  <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Unit Price</th>
                                  <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Net Total</th>
                                </tr>
                                {item.items.map((subItem, index) => (
                                  <tr key={`${subItem.name}-${index}`} style={data.posScreenStyles.orderHistoryModal.orderDetailsData}>
                                    <td style={data.posScreenStyles.orderHistoryModal.orderDetailsDesc}>{subItem.name}</td>
                                    <td>{subItem.weight}</td>
                                    <td>{subItem.salePrice}</td>
                                    <td>{subItem.nettotal}</td>
                                  </tr>
                                ))}
                              </table>
                              <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsTotalTaxes}>
                                <Col span={12}>
                                  <p>Taxes</p>
                                </Col>
                                <Col span={12}>
                                  <p style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}>{parseFloat(item.tax).toFixed(2)}</p>
                                </Col>
                              </Row>
                              <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsTotalTaxes}>
                                <Col span={12}>
                                  <p>Total</p>
                                </Col>
                                <Col span={12}>
                                  <p style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}>{parseFloat(item.total).toFixed(2)}</p>
                                </Col>
                              </Row>
                              <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsButtons}>
                                <Col span={24}>
                                  <Button size="large" style={data.posScreenStyles.orderHistoryModal.rePrintBtn}>
                                    Print
                                  </Button>
                                </Col>
                                {/*  <Col span={12}>
                                  <Button size="large" style={data.posScreenStyles.orderHistoryModal.returnBtn}>
                                    Return
                                  </Button>
                                </Col> */}
                              </Row>
                            </Row>
                          ) : (
                            ""
                          )}
                        </Card>
                      ) : (
                        ""
                      )
                    )}
                  </Scrollbars>
                </Card>
              </Row>
            </TabPane>
            <TabPane tab="Pending" key="3">
              <Row gutter={[8, 8]} style={{ margin: "0px" }}>
                <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
                  <Scrollbars className="orderHistoryScroll">
                    <Button
                      style={data.posScreenStyles.orderHistoryModal.syncOrderBtn}
                      onClick={() => {
                        syncOrders("orderHistory");
                      }}
                    >
                      Sync Now
                    </Button>
                    {orderHistoryDetails.map((item) =>
                      parseInt(item.isSynced) === 0 || parseInt(item.isSynced) === 2 ? (
                        <Card key={item.documentno} style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard} bodyStyle={{ padding: 10 }}>
                          <Row>
                            <Col span={11}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderDate}>{item.orderTime}</p>
                            </Col>
                            <Col span={11}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>{item.customer.name}</p>
                            </Col>
                            <Col span={2}>
                              <img
                                style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}
                                src={DropdownCircle}
                                alt=""
                                width={15}
                                height={15}
                                onClick={() => showOrderHistoryLine(item.sOrderID)}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={12}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.documentno}</p>
                            </Col>
                            <Col span={10}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>{item.isSynced === 1 ? "Success" : "Pending"}</p>
                            </Col>
                            <Col span={2}></Col>
                          </Row>
                          {item.sOrderID === selectedOrderHistoryLine ? (
                            <Row>
                              <table style={data.posScreenStyles.orderHistoryModal.orderDetailsTable}>
                                <tr style={data.posScreenStyles.orderHistoryModal.orderDetailsTableHeader}>
                                  <th>Description</th>
                                  <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Qty</th>
                                  <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Unit Price</th>
                                  <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Net Total</th>
                                </tr>
                                {item.items.map((subItem, index) => (
                                  <tr key={`${subItem.name}-${index}`} style={data.posScreenStyles.orderHistoryModal.orderDetailsData}>
                                    <td style={data.posScreenStyles.orderHistoryModal.orderDetailsDesc}>{subItem.name}</td>
                                    <td>{subItem.weight}</td>
                                    <td>{subItem.salePrice}</td>
                                    <td>{subItem.nettotal}</td>
                                  </tr>
                                ))}
                              </table>
                              <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsTotalTaxes}>
                                <Col span={12}>
                                  <p>Taxes</p>
                                </Col>
                                <Col span={12}>
                                  <p style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}>{parseFloat(item.tax).toFixed(2)}</p>
                                </Col>
                              </Row>
                              <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsTotalTaxes}>
                                <Col span={12}>
                                  <p>Total</p>
                                </Col>
                                <Col span={12}>
                                  <p style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}>{parseFloat(item.total).toFixed(2)}</p>
                                </Col>
                              </Row>
                              <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsButtons}>
                                <Col span={24}>
                                  <Button size="large" style={data.posScreenStyles.orderHistoryModal.rePrintBtn}>
                                    Print
                                  </Button>
                                </Col>
                                {/* <Col span={12}>
                                  <Button size="large" style={data.posScreenStyles.orderHistoryModal.returnBtn}>
                                    Return
                                  </Button>
                                </Col> */}
                              </Row>
                            </Row>
                          ) : (
                            ""
                          )}
                        </Card>
                      ) : (
                        ""
                      )
                    )}
                  </Scrollbars>
                </Card>
              </Row>
            </TabPane>
          </Tabs>
        </Row>
      </Modal>
      {/* ORDER HISTORY MODAL END*/}

      {/* PARKED BILLS MODAL BEGIN*/}
      <Modal
        // visible={displayParkedBillModal}
        closable={false}
        footer={null}
        bodyStyle={{ padding: "20px 15px 15px" }}
      >
        <div>
          <Row>
            <Col span={9}>
              <ArrowLeftOutlined style={data.posScreenStyles.orderHistoryModal.backArrowIcon} onClick={closeParkedBillModal} />
            </Col>
            <Col span={15}>
              <p style={data.posScreenStyles.parkedBillModal.parkedBillsHistoryModalTitle}>Parked Orders</p>
            </Col>
          </Row>
          <Row style={{ paddingBottom: "12px" }}>
            <Col span={24}>
              <Input
                placeholder={`Customer Search Key`}
                size="large"
                allowClear={false}
                style={data.posScreenStyles.orderHistoryModal.orderHistorySearchInput}
                onPressEnter={searchParkedBill}
                onChange={handleParkedBillSearchInput}
                value={parkedBillSearchInput}
                suffix={<SearchOutlined onClick={searchParkedBill} style={data.posScreenStyles.customerModal.searchIcon} />}
              />
            </Col>
          </Row>
          <Row>
            <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
              <Scrollbars className="orderHistoryScroll">
                {filterdParkedList?.map((item, index) => (
                  <Card
                    tabIndex={0}
                    onKeyDown={(e) => (e.keyCode === 13 ? selectParkedBill(item) : null)}
                    key={`${index + 1}`}
                    style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard}
                    bodyStyle={{ padding: 10 }}
                    onClick={() => selectParkedBill(item, "management")}
                  >
                    <Row>
                      <Col span={12}>
                        <p style={data.posScreenStyles.orderHistoryModal.orderDate}>{item.parkedTime}</p>
                      </Col>
                      <Col span={12}>
                        <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>{index + 1}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.parkedCart.customer.name}</p>
                      </Col>
                      <Col span={12}>
                        <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>Parked</p>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </Scrollbars>
            </Card>
          </Row>
        </div>
      </Modal>
      {/* PARKED BILLS MODAL END*/}
    </Fragment>
  );
};

export default PointOfSaleCoreModals;