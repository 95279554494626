import Axios from "axios";
import openDrawer from "./openDrawer";
import PoleDisplay from "./poleDisplay";
import GiftCardHCPrint from "./giftCardHCPrinter";

const HCPrint = (reciptData, PStatus) => {
  let printReciptData = {...reciptData};
  if (!PStatus && !printReciptData.draftFlag && !printReciptData.layAway) {
    PoleDisplay(printReciptData.items, "Show payment success")
  }
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const sales_receipt_copies = posConfig?.sales_receipt_copies && posConfig?.sales_receipt_copies !== null ? posConfig?.sales_receipt_copies : 0;
  const return_receipt_copies = posConfig?.return_receipt_copies && posConfig?.return_receipt_copies !== null ? posConfig?.return_receipt_copies : 0;
  const layaway_receipt_copies = posConfig?.layaway_receipt_copies && posConfig?.layaway_receipt_copies !== null ? posConfig?.layaway_receipt_copies : 0;
  const layaway_paid_receipt_copies = posConfig?.layaway_paid_receipt_copies && posConfig?.layaway_paid_receipt_copies !== null ? posConfig?.layaway_paid_receipt_copies : 0;

  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  let roundOffValue = 0

  let tableDetails = JSON.parse(localStorage.getItem("tableName"));
  let salesRep = tillData.tillAccess.csBunit.salesRep.filter((item) => item.salesRepresentId === tableDetails?.waiter)
  if (salesRep.length > 0) {
    printReciptData.waiter = salesRep[0]?.name;
  }
  // const mergedData = printReciptData.items.reduce((result, item) => {
  //   const existingItem = result.find(i => i.productId === item.productId && item.canceled !== "Y" && item?.giftCardType !== "DIG");
  //   if (existingItem) {
  //     existingItem.weight += item.weight;
  //     existingItem.nettotal += item.nettotal;
  //   } else if (item.canceled !== "Y") {
  //     result.push({ ...item });
  //   }
  //   return result;
  // }, []);
  // printReciptData.items = mergedData;
  let customerFlag = false
  let Payments1
  let taxDetails = '';

  printReciptData.payments.map((item) => {
    if (item.name === "Credit") {
      customerFlag = true
    }
    Payments1 += `
  <line>
      <text align="left" length="13"> ${item.name}</text>
      <text align="left" length="13">${item.amount}</text>
      <text align="left" length="13">${item.isoCode}</text>
  </line>`
  })
  const groupedItems = {};
  printReciptData.items.forEach(item => {
    const key =item.tax && (item.tax.rate !== undefined && item.tax.rate !== null) ? item.tax.rate : item.taxRate;
    if (!groupedItems[key]) {
      groupedItems[key] = [];
    }
    groupedItems[key].push(item);
  });
  for (const taxRate in groupedItems) {
    const totalTaxAmount = groupedItems[taxRate].reduce((total, item) => total + (item.tax && (item.tax.rate !== undefined && item.tax.rate !== null) ?parseFloat(parseFloat(item.linetax).toFixed(2)) : parseFloat(parseFloat(item.taxAmount).toFixed(2))), 0);
    const totalNetTotal = groupedItems[taxRate].reduce((total, item) => total + (item.tax && (item.tax.rate !== undefined && item.tax.rate !== null) ? parseFloat(parseFloat(item.linegross).toFixed(2)) :  parseFloat(parseFloat(item.nettotal).toFixed(2))), 0);
    taxDetails += `<line>
      <text align="left" length="10">${taxRate}%</text>
      <text align="left" length="10">${(totalNetTotal - totalTaxAmount).toFixed(2)}</text>
      <text align="left" length="10">${totalTaxAmount.toFixed(2)}</text>
      <text align="left" length="12">${totalNetTotal.toFixed(2)}</text>
    </line>`;
  }
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  let itemsDetails = '';
  let discountDetails = '';
  let staffDetails = '';
  let changeDetails = '';
  let changeData = '';
  let PrintedOn = '';
  let OrderType = 'Tax Invoice';
  let ArabicOrderType = 'فاتورة ضريبية'
  let ORDER_TYPE1_TEXT = '';
  let paymentTypeAndAmount = '';
  let negativeTotalAndQty = false;
  let showOnlyPaymentMethod = false;
  let paymentStatus = PStatus ? 'This receipt is closed' : 'You have paid';
  let arabicStatus = PStatus ? 'هذا الإيصال مغلق' : 'كنت قد دفعت'
  if (printReciptData.items.length > 0 && printReciptData.draftFlag && !printReciptData.isReturn && !printReciptData.layAway) {
    paymentStatus = 'DRAFT';
    arabicStatus = 'مسودة'
  }
  else if (printReciptData.items.length > 0 && printReciptData.draftFlag && printReciptData.isReturn && !printReciptData.layAway) {
    paymentStatus = 'Return (Draft)';
    arabicStatus = 'مسودة العودة'
  }
  if (printReciptData.isReturn) {
    OrderType = 'Tax Credit Note'
    ArabicOrderType = 'مذكرة الائتمان الضريبي'
    ORDER_TYPE1_TEXT = `<line size="1">
    <text align="center" length="42">Return</text>
</line>`
    negativeTotalAndQty = true
    ArabicOrderType = 'مذكرة الائتمان الضريبي'
  }
  if (printReciptData.isReturn && !printReciptData.draftFlag) {
    paymentStatus = 'Returned'
    arabicStatus = 'تم العودة'
  }
  else if (printReciptData.layAway === "Y" && printReciptData.paid === 0) {
    OrderType = 'Tax Invoice'
    ArabicOrderType = 'فاتورة ضريبية'
    ORDER_TYPE1_TEXT = `<line size="1">
    <text align="center" length="42">LAYAWAY</text>
</line>`
    negativeTotalAndQty = false
  }
  // if (printReciptData.layAway === "Y" && printReciptData.paid !== 0 && !printReciptData.draftFlag) {
  //   showOnlyPaymentMethod = true
  //   paymentStatus = 'This receipt is closed'
  //   arabicStatus = 'هذا الإيصال مغلق'
  // }
  if (printReciptData.layAway === "Y" && printReciptData.paid === 0 ) {
    paymentStatus = 'NOT PAID'
    arabicStatus = ''
    showOnlyPaymentMethod = true
  }

  printReciptData.items.forEach(item => {
    itemsDetails += `<line>
    <text align="left" length="21">${item.name?.substring(0, 20).replace(/&/g, '&amp;')}</text>
    <text align="right" length="5">${item.weight}</text>
    <text align="right" length="8">${parseFloat(item.realPrice ? item.realPrice :item.salePrice).toFixed(2)}</text>
    <text align="right" length="8">${parseFloat((item.realPrice ? item.realPrice :item.salePrice) * (item.weight)).toFixed(2)}</text>
  </line>`;

    if (item.name2 !== null && item.name2 !== undefined) {
      itemsDetails += `<line>
          <text align="right" length="21">${item.name2}</text>
      </line>`;
    }
    if(item.pricingRule?.name){
      itemsDetails += `<line>
          <text align="left" length="34">${item.pricingRule.name?.replace(/&/g, '&amp;')}</text>
          <text align="right" length="8">-${(item.discount).toFixed(2)} </text>
      </line>`;
    }
  else  if (item.discountName) {
      itemsDetails += `<line>
          <text align="left" length="34">${item.discountName.replace(/&/g, '&amp;')}</text>
          <text align="right" length="8">-${(item.discount).toFixed(2)} </text>
      </line>`;
    }
  });


  printReciptData.payments.forEach(item => {
    if (item.amount !== 0) {
      paymentTypeAndAmount += `<line>
        <text align="left" length="20">${item.name}</text>
        <text align="right" length="20">${parseFloat(item.name === "Cash"  && printReciptData.tenderedAmount ? printReciptData.tenderedAmount : item.amount)?.toFixed(2)}</text>
      </line>`;
    }
  });
  if (customerFlag) {
    staffDetails += ` <line><text align="left" length="30" > Staff:@@staff@@</text></line>`
  }
  const groupAndSumItems = (items) => {
    const groupedItems = {};
    items.forEach((item) => {
      if (groupedItems.hasOwnProperty(item.discountName)) {
        groupedItems[item.discountName].value += item.value;
      } else if (parseFloat(item.discount) > 0) {
        groupedItems[item.discountName] = {
          discountName: item.discountName,
          discount: item.discount,
        };
      }
    });
    return Object.values(groupedItems);
  }
  const result = groupAndSumItems(printReciptData.items);

  if (result.length > 0) {
    discountDetails += `<line> <text>---------------------------------------------</text> <text align="center" length="42">----------------------------------------------------</text> </line>`;

    discountDetails += result.map((data) => {
      return `<line><text align="left" length="20">${data.discountName}</text><text align="left" length="10">${data.discount}</text></line>`;
    }).join('');

    discountDetails += `<line> <text>---------------------------------------------</text> <text align="center" length="42">----------------------------------------------------</text> </line>`;
  }
  else {
    discountDetails += `<line> <text>---------------------------------------------</text> <text align="center" length="42">----------------------------------------------------</text> </line>`;
  }
  changeDetails += `<line>
    <text align="left" length="14"> Change</text>
    <text align="left" length="14">${currenciesList[0].isoCode === "USD" ? parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ? parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "EUR" ? parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ? parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}</text>
    <text align="left" length="14">${tillData.tillAccess.csBunit.currencies[0].isoCode}</text>
</line>`
  if (!PStatus && !showOnlyPaymentMethod) {
    changeData += `
  <line>
			<text align="left" length="20">Change</text>
			<text align="right" length="20">${parseFloat(printReciptData.change).toFixed(2)}</text>
		</line>`
  }
  let customer = `<line>
<text align="left" length="22">Customer</text>
<text>${printReciptData.firstName}</text>
</line>`
  let customerName = `
<line>
    <text align="left" length="22">Customer</text>
    <text align="left" length="20">${printReciptData.customer?.name?.substring(0, 20).replace(/&/g, '&amp;')}</text>
</line>`;

  if (printReciptData.customer?.name?.length > 20) {
    customerName += `
<line>
    <text align="left" length="22"></text>
    <text align="left" length="20">${printReciptData.customer.name.substring(20).replace(/&/g, '&amp;')}</text>
</line>`;
  }
  let discountTotal = `<line></line>
<line size="1">
  <text align="left" length="25">TOTAL Savings AED</text>
  <text align="right" length="15">${printReciptData.discount ? printReciptData.discount?.toFixed(2) : ""}</text>
</line>`;

  if (!printReciptData.isReturn) {
    discountTotal += `<line size="1">
    <text align="left" length="30">إجمالي التوفير بالدرهم الإماراتي</text>
  </line>`;
  }

  discountTotal += `<line></line>`;

  let balanceloyaltyPoints = `
  <line>
			<text align="left" length="20">Loyalty Balance</text>
			<text align="right" length="20">${Math.abs(printReciptData?.customer?.retlLoyaltyBalance).toFixed(2)}</text>
		</line>`

  if (PStatus) {
    const currentDate = new Date();

    const formattedDate = currentDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).replace(/\//g, '-');

    const formattedTime = currentDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    PrintedOn = `
      <line> 
        <text align="left" length="22">Printed On</text>
        <text>${formattedDate}</text> <text> </text> <text>${formattedTime}</text> 
      </line>`;
  }
  let decodedData = tillData.tillAccess.cwrTill.printTemplate.xmlcode2;
  decodedData = decodedData.replaceAll("@@OrderType@@", `${OrderType}`);
  decodedData = decodedData.replaceAll("@@arabicOrderType@@", `${ArabicOrderType}`);
  decodedData = decodedData.replaceAll("@@ORDER_TYPE1_TEXT@@", `${ORDER_TYPE1_TEXT !== '' ? ORDER_TYPE1_TEXT : ''}`);
  decodedData = decodedData.replaceAll("@@date@@", `${printReciptData.orderDate?.split('-').reverse().join('-')}`);
  decodedData = decodedData.replaceAll("@@printReciptDataDocumentno@@", `${printReciptData.documentno ? printReciptData.documentno : ""}`);
  decodedData = decodedData.replaceAll("@@tillIdReceipt@@", printReciptData.documentno ? `${printReciptData.documentno}` : "");
  decodedData = decodedData.replaceAll("@@waiterName@@", `${printReciptData.salesHistory === "Y" ? printReciptData.waiter !== null && printReciptData.waiter !== undefined ? printReciptData.waiter : "" : salesRep[0]?.name === undefined && salesRep[0]?.name == null ? "" : salesRep[0]?.name}`);
  decodedData = decodedData.replaceAll("@@staffDetails@@", `${staffDetails}`);
  decodedData = decodedData.replaceAll("@@staff@@", `${customerFlag ? printReciptData.customer.name : ""}`);
  decodedData = decodedData.replaceAll("@@NoofGuests@@", ``);
  decodedData = decodedData.replaceAll("@@TableName@@", `${printReciptData.salesHistory === "Y" ? printReciptData.table ? printReciptData.table : "" : tableDetails?.table ? "" : tableDetails?.table}`);
  decodedData = decodedData.replaceAll("@@Time@@", printReciptData.orderTime?.slice(11, -3));
  decodedData = decodedData.replaceAll("@@dateTime@@", `${printReciptData.orderTime ? new Date(printReciptData.orderTime).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }) : ''}`);
  decodedData = decodedData.replaceAll("@@PrintedOn@@", `${PrintedOn}`);
  decodedData = decodedData.replaceAll("@@user@@", `${localStorage.getItem("username")}`);
  decodedData = decodedData.replaceAll("@@TillId@@", `${tillData.tillAccess.cwrTill.till}`);
  decodedData = decodedData.replaceAll("@@itemName@@", `${tillData.tillAccess.cwrTill.till}`);
  decodedData = decodedData.replaceAll("@@itemQty@@", `${tillData.tillAccess.cwrTill.till}`);
  decodedData = decodedData.replaceAll("@@itemPrice@@", `${tillData.tillAccess.cwrTill.till}`);
  decodedData = decodedData.replaceAll("@@itemTotal@@", `${tillData.tillAccess.cwrTill.till}`);
  decodedData = decodedData.replaceAll("@@itemDetails@@", `${itemsDetails}`);
  decodedData = decodedData.replaceAll("@@discountDetails@@", `${discountDetails}`);
  decodedData = decodedData.replaceAll("@@discountAmount@@", ``);
  decodedData = decodedData.replaceAll("@@changeDetails@@", `${changeDetails}`);
  decodedData = decodedData.replaceAll("@@change@@", `${changeData}`);
  decodedData = decodedData.replace("@@paymentType@@", printReciptData.payments[0]?.name ? `${printReciptData.payments[0].name}` : "");
  decodedData = decodedData.replace("@@paymentTypeAndAmount@@", `${paymentTypeAndAmount}`);
  decodedData = decodedData.replace("@@paymentAmount@@", printReciptData.payments[0]?.amount ? `${parseFloat(printReciptData.payments[0].amount).toFixed(2)}` : "");
  decodedData = decodedData.replaceAll("@@Total@@", `${printReciptData.total ? parseFloat(printReciptData.total).toFixed(2) : ""}`);
  decodedData = decodedData.replaceAll("@@KIP@@", '');
  decodedData = decodedData.replaceAll("@@THB@@", '');
  decodedData = decodedData.replaceAll("@@USD@@", '');
  decodedData = decodedData.replaceAll("@@EUR@@", '');
  decodedData = decodedData.replaceAll("@@CNY@@", '');

  // decodedData = decodedData.replaceAll("@@KIP@@", `${currenciesList[0].isoCode === "USD" ? parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ? parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "EUR" ? parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ? parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  // decodedData = decodedData.replaceAll("@@THB@@", `${currenciesList[0].isoCode === "USD" ? parseFloat((printReciptData.total * THB[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat((printReciptData.total * THB[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ? parseFloat((printReciptData.total * THB[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "EUR" ? parseFloat((printReciptData.total * THB[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ? parseFloat((printReciptData.total * THB[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat((printReciptData.total * THB[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  // decodedData = decodedData.replaceAll("@@USD@@", `${currenciesList[0].isoCode === "USD" ? parseFloat((printReciptData.total * USD[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat((printReciptData.total * USD[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ? parseFloat((printReciptData.total * USD[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "EUR" ? parseFloat((printReciptData.total * USD[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ? parseFloat((printReciptData.total * USD[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat((printReciptData.total * USD[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  // decodedData = decodedData.replaceAll("@@EUR@@", `${currenciesList[0].isoCode === "EUR" ? parseFloat((printReciptData.total * EUR[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat((printReciptData.total * EUR[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ? parseFloat((printReciptData.total * EUR[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "EUR" ? parseFloat((printReciptData.total * EUR[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ? parseFloat((printReciptData.total * EUR[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat((printReciptData.total * EUR[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  // decodedData = decodedData.replaceAll("@@CNY@@", `${currenciesList[0].isoCode === "CNY" ? parseFloat((printReciptData.total * CNY[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat((printReciptData.total * CNY[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ? parseFloat((printReciptData.total * CNY[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "CNY" ? parseFloat((printReciptData.total * CNY[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ? parseFloat((printReciptData.total * CNY[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat((printReciptData.total * CNY[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  decodedData = decodedData.replaceAll("@@Payments1@@", `${Payments1}`);
  decodedData = decodedData.replaceAll("@@taxDetails@@", `${taxDetails}`);
  decodedData = decodedData.replaceAll("@@taxTotal@@", `${parseFloat(printReciptData.tax).toFixed(2)}`);
  decodedData = decodedData.replaceAll("@@Payment Status@@", "Paid");
  decodedData = decodedData.replaceAll("@@status@@", `${paymentStatus}`);
  decodedData = decodedData.replaceAll("@@arabicStatus@@", `${arabicStatus}`);
  decodedData = decodedData.replaceAll("@@Customer@@", `${printReciptData.customer.name ? `${customerName}` : ""}`);
  decodedData = decodedData.replaceAll("@@discountTotal@@", `${!printReciptData.isReturn && printReciptData.discount !== 0 ? `${discountTotal}` : ""}`);
  decodedData = decodedData.replaceAll("@@balanceloyaltyPoints@@", `${(printReciptData.payments && printReciptData.payments.some(payment => payment.isloyalty)) ? balanceloyaltyPoints : ""}`);

  if (printReciptData.items.length > 0 && !printReciptData.draftFlag && printReciptData.isReturn && printReciptData.layAway === "N" && return_receipt_copies !== 0 && !PStatus) {
    let successfulPrints = 0;
    for (let i = 0; i < return_receipt_copies; i++) {
      Axios
        .post(`${printerURL}printer`, decodedData, {
          headers: {
            'Content-Type': 'application/xml; charset=UTF-8',
            'Accept': 'application/xml'
          }
        })
        .then(() => {
          console.log("XML print success !");
          successfulPrints++;
          if (successfulPrints === return_receipt_copies) {
            if (printReciptData.layAway === "N" && !PStatus && !printReciptData.draftFlag && printReciptData?.payments?.some(payment => payment?.name === "Cash")) {
              openDrawer()
            }
            printReciptData.items.forEach((data) => {
              if (printReciptData.isReturn && data?.giftCardType === "DIG") {
                GiftCardHCPrint(data, "Credit Note");
              } else if (data?.giftCardType === "DIG") {
                GiftCardHCPrint(data, "Gift Card");
              }
            });
          }
        })
        .catch((response) => {
          console.log("XML print failed !", response);
        });
    }
  
  }
  else if (printReciptData.items.length > 0 && !printReciptData.draftFlag && !printReciptData.isReturn && printReciptData.layAway === "N" && sales_receipt_copies !== 0 && !PStatus) {
    let successfulPrints = 0;
    for (let i = 0; i < sales_receipt_copies; i++) {
      Axios
        .post(`${printerURL}printer`, decodedData, {
          headers: {
            'Content-Type': 'application/xml; charset=UTF-8',
            'Accept': 'application/xml'
          }
        })
        .then(() => {
          console.log("XML print success !");
          successfulPrints++;
          if (successfulPrints === sales_receipt_copies) {
            if (printReciptData.layAway === "N" && !PStatus && !printReciptData.draftFlag && printReciptData?.payments?.some(payment => payment?.name === "Cash")) {
              openDrawer()
            }
            printReciptData.items.forEach((data) => {
              if (printReciptData.isReturn && data?.giftCardType === "DIG") {
                GiftCardHCPrint(data, "Credit Note");
              } else if (data?.giftCardType === "DIG") {
                GiftCardHCPrint(data, "Gift Card");
              }
            });
          }
        })
        .catch((response) => {
          console.log("XML print failed !", response);
        });
    }

  }
  else if (printReciptData.items.length > 0 && !printReciptData.draftFlag && printReciptData.layAway === "Y" && printReciptData.paid === 0 && layaway_receipt_copies !== 0 && !PStatus) {
    let successfulPrints = 0; 
    for (let i = 0; i < layaway_receipt_copies; i++) {
      Axios
        .post(`${printerURL}printer`, decodedData, {
          headers: {
            'Content-Type': 'application/xml; charset=UTF-8',
            'Accept': 'application/xml'
          }
        })
        .then(() => {
          console.log("XML print success !");
          successfulPrints++;
          if (successfulPrints === layaway_receipt_copies) {
            printReciptData.items.forEach((data) => {
              if (printReciptData.isReturn && data?.giftCardType === "DIG") {
                GiftCardHCPrint(data, "Credit Note");
              } else if (data?.giftCardType === "DIG") {
                GiftCardHCPrint(data, "Gift Card");
              }
            });
          }
        })
        .catch((response) => {
          console.log("XML print failed !", response);
        });
    
    }

  }
  else if (printReciptData.items.length > 0 && !printReciptData.draftFlag && printReciptData.layAway === "Y" && printReciptData.paid !== 0 && layaway_paid_receipt_copies !== 0 && !PStatus) {
    let successfulPrints = 0; 
    for (let i = 0; i < layaway_paid_receipt_copies; i++) {
      Axios
        .post(`${printerURL}printer`, decodedData, {
          headers: {
            'Content-Type': 'application/xml; charset=UTF-8',
            'Accept': 'application/xml'
          }
        })
        .then(() => {
          console.log("XML print success !");
          successfulPrints++;
          if (successfulPrints === layaway_paid_receipt_copies) {
            printReciptData.items.forEach((data) => {
              if (printReciptData.isReturn && data?.giftCardType === "DIG") {
                GiftCardHCPrint(data, "Credit Note");
              } else if (data?.giftCardType === "DIG") {
                GiftCardHCPrint(data, "Gift Card");
              }
            });
          }
        })
        .catch((response) => {
          console.log("XML print failed !", response);
        });
    
    }

  }
  else {
    Axios
      .post(`${printerURL}printer`, decodedData, {
        headers: {
          'Content-Type': 'application/xml; charset=UTF-8',
          'Accept': 'application/xml'
        }
      })
      .then(() => {
        console.log("XML print success !");
        if ((printReciptData.layAway === "N" || (printReciptData.layAway === "Y" && printReciptData.paid !== 0)) && !PStatus && !printReciptData.draftFlag && printReciptData?.payments?.some(payment => payment?.name === "Cash")) {
          openDrawer()
        }
        printReciptData.items.forEach((data) => {
          if (printReciptData.isReturn && data?.giftCardType === "DIG") {
            GiftCardHCPrint(data, "Credit Note");
          } else if (data?.giftCardType === "DIG") {
            GiftCardHCPrint(data, "Gift Card");
          }
        });
      })
      .catch((response) => {
        console.log("XML print failed !", response);
      });
  }
}
export default HCPrint;