import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Typography, Row, Col, Divider, Collapse, Select, Input, Button } from "antd";
import menuIcon from "../../assets/images/menu.svg";
import searchIcon from "../../assets/images/mingcute_search-line.svg";
import filterIcon from "../../assets/images/mdi_filter.svg";
import dropdownIcon from "../../assets/images/mingcute_down-line.svg";
import refreshIcon from "../../assets/images/Vector.svg";
import PieChart from "../../assets/images/carbon_in-progress.svg";
import TickCircle from "../../assets/images/mdi_tick-circle.svg";
import Error from "../../assets/images/bxs_error.svg";
import Arrow from "../../assets/images/arrow.svg";
import db from "../../database";
import Axios from "axios";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { Scrollbars } from "react-custom-scrollbars";

function Dashboard2() {
  const { Panel } = Collapse;
  const [productsList, setProductsList] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [ordersCopy, setOrdersCopy] = useState([]);
  const [selectGiftCardItem, setSelectGiftCardItem] = useState([]);
  const [tillEvents, setTillEvents] = useState([]);
  const [dataSyncSummery, setDataSyncSummery] = useState([]);
  const [logActivity, setLogActivity] = useState([]);
  let tillData = JSON.parse(localStorage.getItem("tillData"));
  const [pageRefreshFlag, setPageRefreshFlag] = useState(false);
  const serverUrl = process.env.REACT_APP_serverUrl;

  useEffect(async () => {
    db.products
      .limit(3)
      .toArray()
      .then((productsFetched) => {
        setProductsList([...productsFetched]);
      });

    db.dataSyncSummary
      .where("syncType")
      .equals("Full Sync")
      .reverse()
      .sortBy("syncEndTime")
      .then((latestFullSync) => {
        setDataSyncSummery([latestFullSync[0]]);
      });

    db.logActivity
      .reverse()
      .sortBy("syncEndTime")
      .then((response) => {
        let logData = response.slice(0, 7);

        const typeToHeaderText = {
          GCI: "Gift Card Issue",
          ACT: "Add Customer",
          RCT: "Remove Customer",
          CSU: "Customer Updates",
          LGI: "Login",
          LGO: "Logout",
          CAI: "Cash In",
          CAO: "Cash Out",
          RRC: "Reprint Receipt",
          DLN: "Deleted Lines",
          DON: "Day Opening",
          DCL: "Day Closing",
          DOR: "Delete Order",
          SRD: "Sales Rep Delete",
          LSD: "Line Sales Rep Delete",
          SLR: "Sales Return",
          DPO: "Delete Parked order",
          RQT: "Reduce Qty",
          LAY: "Layaway",
          PAY: "Payment",
          DAP: "Discounts Applied",
          OPK: "Order Parked/Retrieved",
          GCR: "Gift Card Redeem",
          CUP: "Coupons",
          LOR: "Loyalty Redemption",
        };

        logData = logData.map((item) => ({
          ...item,
          title: typeToHeaderText[item.type] || "",
        }));

        setLogActivity(logData);
        console.log(logData, "logData");
      });

    db.productBrands
      .limit(2)
      .toArray()
      .then((brandsFetched) => {
        setBrandsData([...brandsFetched]);
      });

    db.productCategories
      .limit(2)
      .toArray()
      .then((categoriesFetched) => {
        setCategoriesData([...categoriesFetched]);
      });

    db.orders
      .orderBy("orderTime")
      .toArray()
      .then((response) => {
        // Sort by isSynced value first, then by orderTime
        let sortedData = response.sort((a, b) => {
          if (a.isSynced === b.isSynced) {
            return new Date(a.orderTime) - new Date(b.orderTime);
          }
          return a.isSynced - b.isSynced;
        });
        let data = sortedData.slice(0, 10);
        setOrdersCopy([...data]);
      })
      .catch((error) => {
        console.error("Failed to get orders:", error);
      });

    db.giftCardData
      .limit(2)
      .toArray()
      .then((giftCardData) => {
        setSelectGiftCardItem(giftCardData);
      });

    db.tillEvents
      .reverse()
      .sortBy("openingTime")
      .then((sortedData) => {
        let events = sortedData.slice(0, 10);
        setTillEvents(events);
        console.log("Till Events:", events);
      })
      .catch((error) => {
        console.error("Error fetching unsynced events:", error);
      });
  }, [pageRefreshFlag]);

  const formatOrderTime = (orderTime) => {
    const date = new Date(orderTime);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes}${period}`;
  };

  const syncOrders = async (order) => {
    const nWStatus = navigator.onLine ? "online" : "offline";
    let setAuthTokens;
    const authHeaders = await getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    let shouldCallAxios = false;
    const posConfig = JSON.parse(localStorage.getItem("posConfig"));
    if (nWStatus === "online") {
      db.logInformation.toArray().then((fetched) => {
        if (fetched.length > 0) {
          const posLogArray = [];
          const trxId = uuidv4().replace(/-/g, "").toUpperCase();
          fetched.forEach((item) => {
            let value = { ...item };
            db.logInformation.where("id").equals(value.id).delete();
            delete value.id;
            posLogArray.push(`{
          type: "${value.type}", 
          action: "LOG", 
          description: "${value.description}", 
          date: "${value.date}",
          time: "${value.time}",    
          orderNo: "${value.orderNo}",
          remarks: "${value.remarks}",
          transactionId: "${trxId}"
          status: "SCS"
          duration: ${value.duration}
      }`);
            if (posConfig?.[value.type] === "Y") {
              shouldCallAxios = true;
            }
          });
          if (shouldCallAxios) {
            Axios({
              url: serverUrl,
              method: "POST",
              data: {
                query: `mutation {
                upsertPOSLog(order: {
                  tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
                  userId: "${tillData.tillAccess.csUserId}" 
                  bUnitId: "${tillData.tillAccess.csBunit.csBunitId}", 
                  lines: [${posLogArray}]
                }) {
                status   
                message
              }
            }`,
              },
              headers: {
                "Content-Type": "Application/json",
                Authorization: `${setAuthTokens}`,
              },
            });
          }
        }
      });

      const csCurrencyId = localStorage.getItem("csCurrencyId");
      db.orders
        .where("documentno")
        .equals(order.documentno)
        .toArray()
        .then((orders) => {
          const pendingOrdersCount = orders.length;
          if (pendingOrdersCount > 0) {
            for (let i = 0; i < pendingOrdersCount; i += 1) {
              const regularOrders = orders.filter((order) => {
                const isRegularOrderWithPaid = order.layAway === "N" && order.paid !== 0;
                // const parkedOrders = order.
                const isRegularOrderWithoutPaid = (order.layAway === "N" || order.layAway === "Y") && order.paid === 0;
                const cancelledOrders = order.total === 0 && order.totalQty === 0;
                const parkedOrders = order.parked === "Y" && order.paid === 0;
                return isRegularOrderWithPaid || isRegularOrderWithoutPaid || cancelledOrders || parkedOrders;
              });

              if (regularOrders.length > 0) {
                const orderLines = [];
                for (let j = 0; j < regularOrders[i]?.items.length; j += 1) {
                  let unitTax = regularOrders[i].items[j].taxAmount / regularOrders[i].items[j].weight;
                  orderLines.push(`{
                  sOrderlineID: "${uuidv4().replace(/-/g, "").toUpperCase()}",
                  sOrderlineReturnId: ${regularOrders[i].items[j].sOrderlineReturnId ? `"${regularOrders[i].items[j].sOrderlineReturnId}"` : null}
                  created: "${regularOrders[i].orderTime}",
                  createdby: "${regularOrders[i].createdBy}",
                  updated: "${regularOrders[i].orderTime}",
                  updatedby: "${regularOrders[i].createdBy}",
                  sOrderId: "${regularOrders[i].sOrderID}",
                  line: ${(j + 1) * 10},
                  description: "${regularOrders[i].items[j].notes !== undefined && regularOrders[i].items[j].notes !== "" ? regularOrders[i].items[j].notes : ""}",
                  mProductId: "${regularOrders[i].items[j].productId ? regularOrders[i].items[j].productId : regularOrders[i].items[j].mProductId}",
                  csUomId: "${regularOrders[i].items[j].uom ? regularOrders[i].items[j].uom : regularOrders[i].items[j].uomData[0].csUomId}",
                  csTaxId: "${regularOrders[i].items[j].tax ? regularOrders[i].items[j].tax : regularOrders[i].items[j].cTaxId}",
                  qty: ${regularOrders[i].items[j].weight},
                  unitprice: ${parseFloat(regularOrders[i].items[j].realPrice) * (regularOrders[i].items[j].isReturn ? -1 : 1)},
                  netlist: ${parseFloat(regularOrders[i].items[j].netList)}
                  discount: ${regularOrders[i].items[j].discount},
                  returnline: ${regularOrders[i].items[j].isReturn ? regularOrders[i].items[j].isReturn : false},
                  returnQty: ${regularOrders[i].items[j].isReturn === true ? Math.abs(regularOrders[i].items[j].weight) : 0},
                  mBatchId: ${
                    regularOrders[i].items[j].hasOwnProperty("mBatchId") && regularOrders[i].items[j].mBatchId !== null
                      ? `"${regularOrders[i].items[j].mBatchId}"`
                      : regularOrders[i].items[j].hasOwnProperty("mBatch") && regularOrders[i].items[j].mBatch !== null
                      ? `"${regularOrders[i].items[j].mBatch}"`
                      : null
                  },
                  mPricingruleId: ${
                    regularOrders[i].items[j].mPricingruleId !== null && regularOrders[i].items[j].mPricingruleId !== undefined
                      ? `"${regularOrders[i].items[j].mPricingruleId}"`
                      : null
                  },
                  batchedForSale:"${regularOrders[i].items[j].batchedForSale}",
                  batchedForStock:"${regularOrders[i].items[j].batchedForStock}",
                  batchedProduct:"${regularOrders[i].items[j].batchedProduct}",
                  salesRepId: ${
                    regularOrders[i].items[j].salesRepId !== null && regularOrders[i].items[j].salesRepId !== undefined ? `"${regularOrders[i].items[j].salesRepId}"` : null
                  },
                  multiPrice: "${regularOrders[i].items[j].multiPrice}",
                  discountTypeId: null,
                  discountAmount: null,
                  unittax: ${unitTax > 0 ? parseFloat(unitTax.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)) * (regularOrders[i].items[j].isReturn ? -1 : 1) : 0}
                  linetax: ${parseFloat(regularOrders[i].items[j].linetax ? regularOrders[i].items[j].linetax : 0)}
                  linenet: ${regularOrders[i].items[j].linenet ? regularOrders[i].items[j].linenet : 0}
                  linegross: ${regularOrders[i].items[j].linegross ? regularOrders[i].items[j].linegross : 0}
                  netunit: ${
                    Math.abs(regularOrders[i].items[j].grossunit) > 0
                      ? parseFloat((regularOrders[i].items[j].grossunit - unitTax).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)) *
                        (regularOrders[i].items[j].isReturn ? -1 : 1)
                      : 0
                  }
                  netstd: ${Math.abs(regularOrders[i].items[j].netStd) ? parseFloat(regularOrders[i].items[j].netStd) : 0}
                  listprice: ${regularOrders[i].items[j].listPrice ? regularOrders[i].items[j].listPrice : 0}
                  grossunit: ${regularOrders[i].items[j].grossunit ? regularOrders[i].items[j].grossunit : 0}
                  grossstd: ${Math.abs(regularOrders[i].items[j].sunitprice) > 0 ? regularOrders[i].items[j].sunitprice * (regularOrders[i].items[j].isReturn ? -1 : 1) : 0}
                  grosslist: ${regularOrders[i].items[j].listPrice ? regularOrders[i].items[j].listPrice : 0}
                        tax:[{
                          csTaxID: "${regularOrders[i].items[j].tax ? regularOrders[i].items[j].tax : regularOrders[i].items[j].cTaxId}"
                          taxableAmt: ${parseFloat(regularOrders[i]?.items[j]?.linenet?.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision))}
                          taxAmt: ${parseFloat(regularOrders[i].items[j].taxAmount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision))}
                      }]
                    }`);
                }
                const paymentsList = [];
                let amtMax = -100000000000000000000;
                let maxFinPaymentMethod;
                for (let k = 0; k < regularOrders[i]?.payments.length; k += 1) {
                  if (amtMax < parseFloat(regularOrders[i].payments[k].amount)) {
                    amtMax = parseFloat(regularOrders[i].payments[k].amount);
                    maxFinPaymentMethod = regularOrders[i].payments[k].finPaymentmethodId;
                  }
                  if (regularOrders[i].payments[k].name.toLowerCase() === "card") {
                    if (regularOrders[i].payments[k].integratedPayment === true) {
                      paymentsList.push(`{
                      finPaymentmethodID: "${regularOrders[i].payments[k].finPaymentmethodId}",
                      amount: "${parseFloat(parseFloat(regularOrders[i].payments[k].amount).toFixed(2))}"
                      transactionResponse: ${regularOrders[i].cardPaymentData?.response?.length > 0 ? `"${regularOrders[i].cardPaymentData?.response[0]?.resultMessage}"` : ""},
                      transactionId: null,
                      cardType: "",
                      cardNo: "",
                      transactionTime: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                      authorizationCode: null,
                      metaData: [{
                        key: "payload",
                        value: ${regularOrders[i].cardPaymentData?.payload?.length > 0 ? JSON.stringify(JSON.stringify(regularOrders[i].cardPaymentData?.payload[0])) : ""}
                      },
                      {
                        key: "response",
                        value: ${regularOrders[i].cardPaymentData?.response?.length > 0 ? JSON.stringify(JSON.stringify(regularOrders[i].cardPaymentData?.response[0])) : ""}
                      },
                    ]
                    }`);
                    } else {
                      paymentsList.push(`{
                      finPaymentmethodID: "${regularOrders[i].payments[k].finPaymentmethodId}",
                      amount: "${parseFloat(parseFloat(regularOrders[i].payments[k].amount).toFixed(2))}"
                      transactionResponse: null,
                      transactionId: ${regularOrders[i]?.cardPaymentData?.manual?.transactionId ? `"${regularOrders[i]?.cardPaymentData?.manual?.transactionId}"` : null},
                      cardType: ${regularOrders[i].cardPaymentData?.manual?.cardType ? `"${regularOrders[i].cardPaymentData?.manual?.cardType}"` : null},
                      cardNo: ${regularOrders[i].cardPaymentData?.manual?.cardNumber ? `"${regularOrders[i].cardPaymentData?.manual?.cardNumber}"` : null},
                      transactionTime: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                      authorizationCode: ${regularOrders[i]?.cardPaymentData?.manual?.authorization ? `"${regularOrders[i]?.cardPaymentData?.manual?.authorization}"` : null},
                      metaData: []
                    }`);
                    }
                  } else {
                    paymentsList.push(`{
                    finPaymentmethodID: "${regularOrders[i].payments[k].finPaymentmethodId}",
                    amount: "${parseFloat(parseFloat(regularOrders[i].payments[k].amount).toFixed(2))}"
                  }`);
                  }
                }
                let tableData = JSON.parse(localStorage.getItem("tableName"));
                let metaData = [];
                if (localStorage.getItem("dineIn") === "Y") {
                  const keysToCheck = ["guestName", "guestType", "cwrFbTableId", "cwrFbsectionId", "noOfPersons", "referredBy", "orderId"];
                  localStorage.removeItem("tableName");
                  for (const key of keysToCheck) {
                    if (tableData?.hasOwnProperty(key)) {
                      metaData.push(`{
                    key: "${key}",
                    value: "${tableData[key]}"
                  }`);
                    }
                  }
                }
                if (regularOrders[i]?.orderTimeDetails) {
                  Object.keys(regularOrders[i].orderTimeDetails).map((obj) => {
                    metaData.push(`{
                    key: "${obj}",
                    value: "${regularOrders[i].orderTimeDetails[obj]}"
                  }`);
                  });
                }
                let giftCards = [];
                regularOrders[i]?.giftCardData?.map((res) => {
                  giftCards.push(`{
                  redeemRefNo: ${res.redemptionId ? `"${res.redemptionId}"` : null},
                  cardNo:${res.number ? `"${res.number}"` : null},
                  referenceNo: ${res.refId ? `"${res.refId}"` : null}
                }`);
                });

                const paramsInput = {
                  query: `mutation{
            posOrderProcessor(posOrder:{
            sOrderID: "${regularOrders[i].sOrderID}",
            cSClientID: "${tillData.tillAccess.csClientId}",
            cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}",
            created: "${regularOrders[i].orderTime}",
            createdby: "${regularOrders[i].createdBy}",
            updated: "${regularOrders[i].orderTime}",
            updatedby: "${regularOrders[i].createdBy}",
            csDoctypeId: "${tillData.tillAccess.csBunit.cwrCsDoctypeId}",
            sCustomerId: "${tillData.tillAccess.csBunit.b2cCustomer.sCustomer.sCustomerID}",
            sCustomerBillingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
            sCustomerShippingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
            sPricelistId: "${tillData.tillAccess.csBunit.cwrSpricelistId}",
            documentno: "${regularOrders[i].documentno}",
            dateordered: "${regularOrders[i].orderTime}",
            datepromised: "${regularOrders[i].orderTime}",
            csPaymenttermID: null,
            finPaymentmethodId: ${regularOrders[i].layAway === "N" ? (maxFinPaymentMethod ? `"${maxFinPaymentMethod}"` : null) : null},
            csCurrencyId: "${csCurrencyId}",
            mWarehouseId: "${tillData.tillAccess.csBunit.mWarehouse.mWarehouseID}",
            cwrLongitude: "",
            cwrLatitude: "",
            csUserId: "${tillData.tillAccess.csUserId}",
            cwrB2cCustomerId: "${regularOrders[i].customer.cwrCustomerId}",
            orderreference: "",
            cwrPayref: "",
            cwrPayremarks: "",
            description: "${regularOrders[i].description !== undefined && regularOrders[i].description !== "" ? regularOrders[i].description : ""}",
            storeDailyOpsTillid: "${localStorage.getItem("storeDailyOpsTillid")}",
            cwrTillId: "${tillData.tillAccess.cwrTill.cwrTillID}",
            redemption: ${regularOrders[i].redemptionPoints},
            accumulation: ${regularOrders[i].accumulationPoints},
            redeemRefId: "${regularOrders[i].referenceId}",
            roundoff: ${regularOrders[i].roundOff.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)},
            cwrProductQty: ${regularOrders[i].items.length},
            cwrProductCount: ${regularOrders[i].totalQty},
            ofdStatus: "Delivered",
            ofdIspaid: "Y",
            mPricingruleId: ${regularOrders[i].mPricingruleId !== null && regularOrders[i].mPricingruleId !== undefined ? `"${regularOrders[i].mPricingruleId}"` : null},
            cwrSaletypeId: "${regularOrders[i].orderType}",
            salesRepId:${regularOrders[i].salesRepId !== null && regularOrders[i].salesRepId !== undefined ? `"${regularOrders[i].salesRepId}"` : null},
            discAmount: ${parseFloat(regularOrders[i].discount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision))},
            creditAmount: ${regularOrders[i].creditAmount},
            metaData: [${metaData}], 
            giftCard:[${giftCards}],
            pricingCoupon:[{
              mPricingCouponId: ${regularOrders[i]?.couponInput?.length > 0 ? `"${regularOrders[i].couponInput[0].mPricingCouponId}"` : null},
              redemptionCount:${regularOrders[i].couponRedemptionCount ? parseInt(regularOrders[i].couponRedemptionCount) : null},
              referenceId:${regularOrders[i]?.couponInput?.length > 0 ? `"${regularOrders[i].couponInput[0].referenceId}"` : null}
            }]
            orderTotal: ${regularOrders[i].total}
            nettotal: ${parseFloat((regularOrders[i].total - regularOrders[i].tax).toFixed(2))}
            taxamt: ${parseFloat(regularOrders[i].tax.toFixed(2))}
            isReturn: ${regularOrders[i].items.filter((f) => f.isReturn === true).length > 0 ? `"Y"` : `"N"`},
            sOrderReturnId: ${regularOrders[i].items?.[0]?.sOrderReturnId ? `"${regularOrders[i].items?.[0]?.sOrderReturnId}"` : null},
            layAway: ${regularOrders[i].layAway ? `"${regularOrders[i].layAway}"` : null},
            payments: [${paymentsList}],
            tax: ${regularOrders[i].combinedTaxRates ? `[${regularOrders[i].combinedTaxRates}]` : null}
            line: [${orderLines}],
            })
          {
            documentno 
            status
            message
           }
          }
          `,
                };
                Axios({
                  url: serverUrl,
                  method: "POST",
                  data: paramsInput,
                  headers: {
                    "Content-Type": "Application/json",
                    Authorization: `${setAuthTokens}`,
                  },
                })
                  .then(async (response) => {
                    const result = response.data.data.posOrderProcessor;
                    const { status } = result;
                    if (status === "200") {
                      console.info(`Order ${regularOrders[i].documentno} synced to Server`);
                      db.orders.where("sOrderID").equals(regularOrders[i].sOrderID).modify({ isSynced: 1 });
                      let rfidData = [];
                      await db.rfidData.toArray((products) => {
                        products.map((ele) => {
                          if (ele.tag_status === "SO") {
                            rfidData.push(` {
                           tagValue: "${ele.tag_value}"
                           taggingDate: null
                           batchNumber: null
                           batchId: null
                           warehouseId: null
                           tagStatus: "SO"
                           lastScannedDate: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                           scannedBy: null
                           expirydate: null
                           customAttribute: null
                           tagType: null
                           productCode: "${ele.product_code}"
                           }`);
                            //  db.rfidData.update(item[0].tag_value, { tag_status: "SO" });
                          }
                        });
                      });
                      await Axios({
                        url: serverUrl,
                        method: "POST",
                        data: {
                          query: `mutation{
                        RFIDTag(rfidTag:[${rfidData}]){
                        status
                        message
                        }
                        }`,
                        },
                        headers: {
                          "Content-Type": "Application/json",
                          Authorization: `${setAuthTokens}`,
                        },
                      });
                    } else {
                      console.error("Failed Order Sync ====> ", response);
                      const syncFailedCount = parseInt(regularOrders[i].syncAttempts) + 1;
                      if (parseInt(regularOrders[i].syncAttempts) < 100) {
                        db.regularOrders.where("sOrderID").equals(regularOrders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                      } else {
                        db.regularOrders.where("sOrderID").equals(regularOrders[i].sOrderID).modify({ isSynced: 2 });
                      }
                    }
                  })
                  .catch((error) => {
                    // const err = JSON.parse(JSON.stringify(error));
                    // const { message } = err;
                    // if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
                    //   const refToken = tokens.refresh_token;
                    //   const requestBody = {
                    //     grant_type: "refresh_token",
                    //     refresh_token: refToken,
                    //   };
                    //   const config = {
                    //     headers: {
                    //       "Content-Type": "application/x-www-form-urlencoded",
                    //       Authorization: "Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=",
                    //     },
                    //   };
                    //   Axios.post(serverTokenUrl, qs.stringify(requestBody), config).then((newToken) => {
                    //     setAuthTokens=newToken.data;
                    //   });
                    // } else {
                    //   const syncFailedCount = parseInt(orders[i].syncAttempts) + 1;
                    //   if (parseInt(orders[i].syncAttempts) < 100) {
                    //     db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                    //   } else {
                    //     db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ isSynced: 2 });
                    //   }
                    // }
                  });
              } else {
                const layAwayOrders = orders.filter((order) => {
                  return order.layAway === "Y" && order.paid !== 0;
                });

                // const regularOrders = orders.filter(order => order.layAway !== "Y");
                //  console.log(layAwayOrders,"=======>lay")
                if (layAwayOrders.length > 0) {
                  // console.log(layAwayOrders,"->layawayirders")
                  const orderLines = [];
                  for (let j = 0; j < layAwayOrders[i].items.length; j += 1) {
                    let unitPrice = layAwayOrders[i].items[j].nettotal / layAwayOrders[i].items[j].weight;
                    unitPrice = parseFloat(unitPrice).toFixed(2);
                    orderLines.push(`{
               sOrderlineID: "${uuidv4().replace(/-/g, "").toUpperCase()}",
               sOrderlineReturnId: ${layAwayOrders[i].items[j].sOrderlineReturnId ? `"${layAwayOrders[i].items[j].sOrderlineReturnId}"` : null}
               created: "${layAwayOrders[i].orderTime}",
               createdby: "${layAwayOrders[i].createdBy}",
               updated: "${layAwayOrders[i].orderTime}",
               updatedby: "${layAwayOrders[i].createdBy}",
               sOrderId: "${layAwayOrders[i].sOrderID}",
               line: ${(j + 1) * 10},
               description: "",
               mProductId: "${layAwayOrders[i].items[j].productId ? layAwayOrders[i].items[j].productId : layAwayOrders[i].items[j].product.mProductId}",
               csUomId: ${
                 layAwayOrders[i].items[j].productId
                   ? `"${layAwayOrders[i].items[j].uom}"`
                   : layAwayOrders[i].items[j].uom && layAwayOrders[i].items[j].uom.csUomId
                   ? `"${layAwayOrders[i].items[j].uom.csUomId}"`
                   : null
               },                     
               csTaxId: ${
                 layAwayOrders[i].items[j].productId
                   ? `"${layAwayOrders[i].items[j].tax}"`
                   : layAwayOrders[i].items[j].tax?.csTaxID
                   ? `"${layAwayOrders[i].items[j].tax.csTaxID}"`
                   : null
               }
               qty: ${layAwayOrders[i].items[j].weight},
               unitprice: ${unitPrice},
               netlist: ${layAwayOrders[i].items[j].salePrice}
               discount: ${layAwayOrders[i].items[j].discount},
               returnline: ${
                 layAwayOrders[i].items[j].isReturn !== undefined && layAwayOrders[i].items[j].isReturn !== null
                   ? layAwayOrders[i].items[j].isReturn
                   : layAwayOrders[i].items[j].returnline
               },
               returnQty: ${layAwayOrders[i].items[j].isReturn === true ? Math.abs(layAwayOrders[i].items[j].weight) : 0},
               mBatchId: ${layAwayOrders[i].items[j].mBatchId ? `"${layAwayOrders[i].items[j].mBatchId}"` : null},
               mPricingruleId: ${
                 layAwayOrders[i].items[j].mPricingruleId !== null && layAwayOrders[i].items[j].mPricingruleId !== undefined
                   ? `"${layAwayOrders[i].items[j].mPricingruleId}"`
                   : null
               },
               batchedForSale:${layAwayOrders[i].items[j].batchedForSale ? `"${layAwayOrders[i].items[j].batchedForSale}"` : null},
               batchedForStock:${layAwayOrders[i].items[j].batchedForStock ? `"${layAwayOrders[i].items[j].batchedForStock}"` : null},
               batchedProduct:${layAwayOrders[i].items[j].batchedProduct ? `"${layAwayOrders[i].items[j].batchedProduct}"` : null},
               salesRepId: ${
                 layAwayOrders[i].items[j].salesRepId !== null && layAwayOrders[i].items[j].salesRepId !== undefined ? `"${layAwayOrders[i].items[j].salesRepId}"` : null
               },
               multiPrice: ${layAwayOrders[i].items[j].multiPrice ? `"${layAwayOrders[i].items[j].multiPrice}"` : null},
               discountTypeId: null,
               discountAmount: null,
                 }`);
                  }
                  const paymentsList = [];
                  let amtMax = -100000000000000000000;
                  let maxFinPaymentMethod;
                  for (let k = 0; k < layAwayOrders[i].payments.length; k += 1) {
                    if (amtMax < parseFloat(layAwayOrders[i].payments[k].amount)) {
                      amtMax = parseFloat(layAwayOrders[i].payments[k].amount);
                      maxFinPaymentMethod = layAwayOrders[i].payments[k].finPaymentmethodId;
                    }
                    paymentsList.push(`{
                   finPaymentmethodID: "${layAwayOrders[i].payments[k].finPaymentmethodId}",
                   amount: "${layAwayOrders[i].payments[k].amount}"
                 }`);
                  }
                  let tableData = JSON.parse(localStorage.getItem("tableName"));
                  let metaData = [];
                  if (localStorage.getItem("dineIn") === "Y") {
                    const keysToCheck = ["guestName", "guestType", "cwrFbTableId", "cwrFbsectionId", "noOfPersons", "referredBy", "orderId"];
                    localStorage.removeItem("tableName");
                    for (const key of keysToCheck) {
                      if (tableData.hasOwnProperty(key)) {
                        metaData.push(`{
                       key: "${key}",
                       value: "${tableData[key]}"
                     }`);
                      }
                    }
                  }
                  const paramsInput = {
                    query: `mutation{
                  confirmPOSLayawayOrder(posOrder:{
               sOrderID: "${layAwayOrders[i].sOrderID}",
               cSClientID: "${tillData.tillAccess.csClientId}",
               cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}",
               created: "${layAwayOrders[i].orderTime}",
               createdby: "${layAwayOrders[i].createdBy}",
               updated: "${layAwayOrders[i].orderTime}",
               updatedby: "${layAwayOrders[i].createdBy}",
               csDoctypeId: "${tillData.tillAccess.csBunit.cwrCsDoctypeId}",
               sCustomerId: "${tillData.tillAccess.csBunit.b2cCustomer.sCustomer.sCustomerID}",
               sCustomerBillingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
               sCustomerShippingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
               sPricelistId: "${tillData.tillAccess.csBunit.cwrSpricelistId}",
               documentno: "${layAwayOrders[i].documentno}",
               dateordered: "${layAwayOrders[i].orderTime}",
               datepromised: "${layAwayOrders[i].orderTime}",
               csPaymenttermID: null,
               finPaymentmethodId: ${layAwayOrders[i].layAway === "N" ? `"${maxFinPaymentMethod}"` : null},
               csCurrencyId: "${csCurrencyId}",
               mWarehouseId: "${tillData.tillAccess.csBunit.mWarehouse.mWarehouseID}",
               cwrLongitude: "",
               cwrLatitude: "",
               csUserId: "${tillData.tillAccess.csUserId}",
               cwrB2cCustomerId: "${layAwayOrders[i].customer.cwrCustomerId}",
               orderreference: "",
               cwrPayref: "",
               cwrPayremarks: "",
               description: "",
               cwrTillId: "${tillData.tillAccess.cwrTill.cwrTillID}",
               redemption: ${layAwayOrders[i].redemptionPoints ? layAwayOrders[i].redemptionPoints : null},
               accumulation: ${layAwayOrders[i].accumulationPoints ? layAwayOrders[i].accumulationPoints : null},
               roundoff: ${Math.abs(layAwayOrders[i].roundOff.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision))},
               cwrProductQty: ${layAwayOrders[i].items.length},
               cwrProductCount: ${layAwayOrders[i].totalQty},
               ofdStatus: "Delivered",
               ofdIspaid: "Y",
               mPricingruleId: ${layAwayOrders[i].mPricingruleId !== null && layAwayOrders[i].mPricingruleId !== undefined ? `"${layAwayOrders[i].mPricingruleId}"` : null},
               cwrSaletypeId: "${layAwayOrders[i].orderType}",
               salesRepId:${layAwayOrders[i].salesRepId !== null && layAwayOrders[i].salesRepId !== undefined ? `"${layAwayOrders[i].salesRepId}"` : null},
               discAmount: ${layAwayOrders[i].discount},
               creditAmount: ${layAwayOrders[i].creditAmount},
               metaData: [${metaData}], 
               pricingCoupon:{
                 mPricingCouponId:${layAwayOrders[i].mPricingCouponId ? `"${layAwayOrders[i].mPricingCouponId}"` : null},
                 redemptionCount:${layAwayOrders[i].couponRedemptionCount ? parseInt(layAwayOrders[i].couponRedemptionCount) : null},
                 referenceId:${layAwayOrders[i]?.couponInput?.length > 0 ? `"${layAwayOrders[i].couponInput[0].referenceId}"` : null}
               }
               orderTotal: ${layAwayOrders[i].total}
               isReturn: ${layAwayOrders[i].items.filter((f) => f.isReturn === true).length > 0 ? `"Y"` : `"N"`},
               sOrderReturnId: ${layAwayOrders[i].items?.[0]?.sOrderReturnId ? `"${layAwayOrders[i].items?.[0]?.sOrderReturnId}"` : null},
               layAway: "${layAwayOrders[i].layAway}",
               payments: [${paymentsList}],
               line: [${orderLines}],
               })
             {
               documentno 
               status
               message
              }
             }
             `,
                  };
                  // console.log(paramsInput,"-->confirm")
                  Axios({
                    url: serverUrl,
                    method: "POST",
                    data: paramsInput,
                    headers: {
                      "Content-Type": "Application/json",
                      Authorization: `${setAuthTokens}`,
                    },
                  })
                    .then(async (response) => {
                      if (response.statusText === "200") {
                        console.log(response, "----------->response");
                        console.info(`Order ${layAwayOrders[i].documentno} synced to Server`);
                        db.orders.where("sOrderID").equals(layAwayOrders[i].sOrderID).modify({ isSynced: 1 });
                        let rfidData = [];
                        await db.rfidData.toArray((products) => {
                          products.map((ele) => {
                            if (ele.tag_status === "SO") {
                              rfidData.push(` {
                              tagValue: "${ele.tag_value}"
                              taggingDate: null
                              batchNumber: null
                              batchId: null
                              warehouseId: null
                              tagStatus: "SO"
                              lastScannedDate: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                              scannedBy: null
                              expirydate: null
                              customAttribute: null
                              tagType: null
                              productCode: "${ele.product_code}"
                              }`);
                              //  db.rfidData.update(item[0].tag_value, { tag_status: "SO" });
                            }
                          });
                        });
                        await Axios({
                          url: serverUrl,
                          method: "POST",
                          data: {
                            query: `mutation{
                           RFIDTag(rfidTag:[${rfidData}]){
                           status
                           message
                           }
                           }`,
                          },
                          headers: {
                            "Content-Type": "Application/json",
                            Authorization: `${setAuthTokens}`,
                          },
                        });
                      } else {
                        console.error("Failed Order Sync ====> ", response);
                        const syncFailedCount = parseInt(layAwayOrders[i].syncAttempts) + 1;
                        if (parseInt(layAwayOrders[i].syncAttempts) < 100) {
                          db.layAwayOrders.where("sOrderID").equals(layAwayOrders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                        } else {
                          db.layAwayOrders.where("sOrderID").equals(layAwayOrders[i].sOrderID).modify({ isSynced: 2 });
                        }
                      }
                    })
                    .catch((error) => {});
                }
              }
            }
          }
        });
    }
  };

  const syncItems = [
    { title: { label: "Products" }, dataKey: "products" },
    { title: { label: "Categories" }, dataKey: "categories" },
    { title: { label: "Brands" }, dataKey: "brands" },
    { title: { label: "Pricing Rules" }, dataKey: "pricingRules" },
    { title: { label: "POS Sale Types" }, dataKey: "posSaletypes" },
    { title: { label: "Gift Card" }, dataKey: "giftCardData" },
    { title: { label: "UOM" }, dataKey: "UOM" },
    { title: { label: "Approvers" }, dataKey: "approvers" },
    { title: { label: "Loyality" }, dataKey: "loyalityData" },
  ];

  const SyncItem = ({ title, dataKey, data }) => (
    <>
      <Typography.Text style={{ textAlign: "left", fontWeight: 500, fontSize: "1.1vw", color: "rgba(15, 7, 24, 1)", position: "absolute", left: "1.5vw" }}>
        {title.label}
      </Typography.Text>
      {data.map((item, index) => (
        <Col key={index} style={{ marginTop: "5vh" }}>
          <div style={{ width: "25vw", height: "0.6vh", backgroundColor: "rgba(217, 217, 217, 1)", borderRadius: "5px", top: "5vh" }}>
            <div style={{ width: "25vw", height: "100%", backgroundColor: item.syncType == "Full Sync" ? "rgba(0, 128, 0, 1)" : "rgba(255, 0, 0, 1)", borderRadius: "5px" }} />
          </div>

          <div style={{ width: "2vw", height: "2.2vh", left: "26vw", position: "absolute", top: "-2vh" }}>
            <img src={item.syncType == "Full Sync" ? TickCircle : PieChart} style={{ width: "1.4vw", height: "3vh", left: "0.3vw" }} />
          </div>

          <Typography.Text style={{ fontFamily: "Inter", fontSize: "0.7vw", fontWeight: 300, color: "rgba(15, 7, 24, 1)", position: "absolute", paddingTop: "1vh" }}>
            {item.type?.[dataKey]?.incremntalSyncEnd ? item.type?.[dataKey]?.incremntalSyncEnd : item.type?.[dataKey]?.syncEndTime}
          </Typography.Text>

          <Typography.Text
            style={{ fontFamily: "Inter", fontSize: "0.7vw", fontWeight: 300, color: "rgba(15, 7, 24, 1)", position: "absolute", marginLeft: "10vw", paddingTop: "1vh" }}
          >
            Records: {item.type?.[dataKey]?.incremntalSyncRecods ? item.type?.[dataKey]?.incremntalSyncRecods : item.type?.[dataKey]?.records}
          </Typography.Text>

          <Typography.Text
            style={{ fontFamily: "Inter", fontSize: "0.7vw", fontWeight: 300, color: "rgba(15, 7, 24, 1)", position: "absolute", marginLeft: "25vw", paddingTop: "1vh" }}
          >
            {item.syncType == "Full Sync" ? "Synced" : "Pending"}
          </Typography.Text>
        </Col>
      ))}
    </>
  );

  return (
    <div style={{ backgroundColor: "rgba(243, 244, 249, 1)", width: "100vw", minHeight: "100vh", overflow: "hidden" }}>
      <Row gutter={24} style={{ height: "8.5vh", paddingLeft: "2.5vw", paddingRight: "2.5vw", paddingTop: "1vw", display: "flex", alignItems: "center" }}>
        {/* <Col span={1} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <img src={menuIcon} style={{ width: "2vw", height: "2vh", paddingRight: 0, cursor: "pointer" }} />
        </Col> */}

        <Col span={2} style={{ paddingLeft: "1vw", paddingRight: 0, position: "relative" }}>
          <Link to="/pos">
            <img src={Arrow} style={{ width: "2.2vw", height: "3.5vh", cursor: "pointer" }} />
          </Link>
        </Col>

        <Col span={5} style={{ right: "5em", paddingLeft: "1.1vw", paddingRight: 0 }}>
          <Typography.Text style={{ fontWeight: 500, fontSize: "1.65vw", color: "rgba(15, 7, 24, 1)" }}>Data Sync Dashboard</Typography.Text>
        </Col>

        <Col span={7} style={{ right: "7.5em", paddingLeft: "1.2vw", paddingRight: 0 }}>
          <Typography.Text style={{ fontSize: "1vw", color: "rgba(15, 7, 24, 1)" }}>Last Sync: {dataSyncSummery[0]?.syncEndTime}</Typography.Text>
        </Col>

        <Col span={4} style={{ display: "flex", alignItems: "center", paddingLeft: "3.5vw", paddingRight: 0 }}>
          {/* <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      <input type="text" placeholder="Search" style={{ width: "16.6vw",height: "4.3vh",border: "none",borderRadius: "0.3em",outline: "none",fontSize: "1.7vh",verticalAlign: "middle",paddingLeft: "2.5em",}}/>
      <img src={searchIcon} alt="Search" style={{ width: "1.5vw",height: "2.1vh",position: "absolute",left: "0.4em",right:"0.8em",top: "50%",transform: "translateY(-50%)",cursor: "pointer",}}/>
      </div> */}
        </Col>

        <Col span={3} style={{ paddingRight: 0 }}>
          {/* <div style={{ width: "9vw", height: "4.3vh", borderRadius: "0.3125em", background: "rgba(255, 255, 255, 1)" }}>
      <div style={{ position: "relative", width: "100%", height: "100%", left: "0.7vw" }}>
      <img src={filterIcon} alt="Filter" style={{ width: "1.2vw", height: "2.2vh", objectFit: "contain", position: "absolute", top: "50%", transform: "translateY(-50%)" }} />
      <select style={{ paddingTop: "0.3vw", paddingLeft: "2vw", border: "none", outline: "none", borderRadius: "0.3125em", background: "transparent", fontSize: "1vw", appearance: "none", width: "7vw" }}>
        <option value="" selected hidden>Filter</option>
        <option value="option1">Option 1</option>
        <option value="option2">Option 2</option>
        <option value="option3">Option 3</option>
      </select>
      <img src={dropdownIcon} alt="Dropdown" style={{ width: "1.5vw", height: "2.5vh", objectFit: "contain", position: "absolute", top: "50%", right: "0.8vw", transform: "translateY(-50%)" }} />
      </div>
      </div> */}
        </Col>

        <Col span={2} style={{ paddingLeft: "2.1vw", paddingRight: 0 }}>
          <button
            style={{
              width: "8vw",
              height: "4.25vh",
              borderRadius: "0.3em",
              backgroundColor: "rgba(47, 56, 86, 1)",
              color: "white",
              display: "flex",
              alignItems: "center",
              fontSize: "1vw",
            }}
            onClick={() => setPageRefreshFlag(!pageRefreshFlag)}
          >
            <img src={refreshIcon} alt="Refresh" style={{ width: "1em", height: "1em", marginRight: "0.6em", objectFit: "contain", marginLeft: "0.3em" }} />
            Refresh
          </button>
        </Col>
      </Row>

      <Row gutter={24} style={{ paddingLeft: "2.3vw", paddingRight: "2.3vw", paddingTop: "1vw" }}>
        <Col span={8} style={{ paddingLeft: 0 }}>
          <Card style={{ borderRadius: "7px", height: "87vh", marginRight: "-0.4em" }}>
            <div style={{ backgroundColor: "rgba(146, 144, 152, 0.3)", borderRadius: "7px 7px 0 0", height: "5.5vh", width: "31.80vw", paddingTop: "1.4vh", paddingLeft: "1.3vw" }}>
              <Typography.Title level={4} style={{ color: "rgba(15, 7, 24, 1)", fontSize: "1vw", fontWeight: 300 }}>
                Incoming Data
              </Typography.Title>
            </div>
            <Scrollbars style={{ height: "80vh" }} renderTrackHorizontal={(props) => <div {...props} style={{ display: "none" }} className="track-horizontal" />}>
              <Row style={{ paddingLeft: "1.5vw", paddingRight: "1.4vw", height: "13vh", paddingTop: "2vh" }}>
                <>
                  <Typography.Text style={{ textAlign: "left", fontWeight: 500, fontSize: "1.1vw", color: "rgba(15, 7, 24, 1)", position: "absolute", left: "1.5vw" }}>
                    Till Data
                  </Typography.Text>
                  <Col key={"0"} style={{ marginTop: "5vh" }}>
                    <div style={{ width: "25vw", height: "0.6vh", backgroundColor: "rgba(217, 217, 217, 1)", borderRadius: "5px", top: "5vh" }}>
                      <div style={{ width: "25vw", height: "100%", backgroundColor: "rgba(0, 128, 0, 1)", borderRadius: "5px" }} />
                    </div>

                    <div style={{ width: "2vw", height: "2.2vh", left: "26vw", position: "absolute", top: "-2vh" }}>
                      <img src={TickCircle} style={{ width: "1.4vw", height: "3vh", left: "0.3vw" }} />
                    </div>

                    <Typography.Text style={{ fontFamily: "Inter", fontSize: "0.7vw", fontWeight: 300, color: "rgba(15, 7, 24, 1)", position: "absolute", paddingTop: "1vh" }}>
                      {tillData?.time}
                    </Typography.Text>

                    <Typography.Text
                      style={{ fontFamily: "Inter", fontSize: "0.7vw", fontWeight: 300, color: "rgba(15, 7, 24, 1)", position: "absolute", marginLeft: "10vw", paddingTop: "1vh" }}
                    ></Typography.Text>

                    <Typography.Text
                      style={{ fontFamily: "Inter", fontSize: "0.7vw", fontWeight: 300, color: "rgba(15, 7, 24, 1)", position: "absolute", marginLeft: "25vw", paddingTop: "1vh" }}
                    >
                      {"Synced"}
                    </Typography.Text>
                  </Col>
                </>
              </Row>
              {syncItems.map((syncItem, index) => (
                <Row key={index} style={{ paddingLeft: "1.5vw", paddingRight: "1.4vw", height: "13vh", paddingTop: "2vh" }}>
                  <SyncItem title={syncItem.title} dataKey={syncItem.dataKey} data={dataSyncSummery} syncEndTimeTop={syncItem.syncEndTimeTop} />
                </Row>
              ))}
            </Scrollbars>
          </Card>
        </Col>

        <Col span={8}>
          <Card style={{ borderRadius: "7px", height: "87vh", marginLeft: "-0.63em", marginRight: "-0.63em" }}>
            <div style={{ backgroundColor: "rgba(146, 144, 152, 0.3)", borderRadius: "7px 7px 0 0", height: "5.5vh", width: "31.75vw", paddingTop: "1.4vh", paddingLeft: "1.3vw" }}>
              <Typography.Title level={4} style={{ color: "rgba(15, 7, 24, 1)", fontSize: "1vw", fontWeight: 300 }}>
                Outgoing Data
              </Typography.Title>
            </div>

            <Typography.Text style={{ textAlign: "left", fontWeight: 500, fontSize: "1.1vw", color: "rgba(15, 7, 24, 1)", position: "absolute", left: "1.5vw", top: "7.75vh" }}>
              Orders
            </Typography.Text>

            <Row style={{ paddingLeft: "1.5vw", paddingTop: "6vh", paddingRight: "1.4vw", height: "25vh" }}>
              <Scrollbars style={{ width: "100%", height: "35vh" }} className="ordersDataScroll">
                {ordersCopy.map((order, index) => (
                  <Col span={24} key={index} style={{ paddingTop: "1.5vh" }}>
                    <Typography.Text style={{ color: "rgba(15, 7, 24, 1)", fontSize: "0.79vw" }}>
                      {order.isReturn ? (
                        <span style={{ color: "#f0ad4e" }}>Return</span>
                      ) : order.layAway === "Y" ? (
                        <span style={{ color: "#007BFF" }}>Layaway</span>
                      ) : (order.total === 0 || order.total === "0.00") && order.totalQty === 0 ? (
                        "Cancelled"
                      ) : (
                        <span style={{ color: "#28a745" }}>Receipt</span>
                      )}{" "}
                      : {order.documentno}
                    </Typography.Text>

                    <div style={{ paddingTop: "0.5vh" }}>
                      <div style={{ width: "25vw", height: "0.6vh", backgroundColor: "rgba(217, 217, 217, 1)", borderRadius: "5px" }}>
                        <div style={{ width: "25vw", height: "100%", backgroundColor: order.isSynced ? "rgba(0, 128, 0, 1)" : "rgba(248, 197, 25, 1)", borderRadius: "5px" }} />
                      </div>
                    </div>

                    <div style={{ position: "absolute", width: "2vw", height: "2vh", top: "2.6vh", left: "26.2vw" }}>
                      <img src={order.isSynced ? TickCircle : PieChart} style={{ width: "1.4vw", height: "3vh", top: "0.8vh", left: "0.5vw", position: "absolute" }} />
                    </div>

                    <Typography.Text style={{ paddingTop: "0.4vh", position: "absolute", fontWeight: 400, fontSize: "0.7vw" }}>{order.orderTime}</Typography.Text>

                    <Typography.Text style={{ fontFamily: "Inter", fontSize: "0.7vw", fontWeight: 300, color: "rgba(15, 7, 24, 1)", paddingTop: "2.5vh", marginLeft: "25.5vw" }}>
                      {order.isSynced ? "Synced" : "Pending"}
                    </Typography.Text>
                  </Col>
                ))}
              </Scrollbars>
            </Row>

            <div style={{ position: "absolute", top: "46vh", left: "1.5vw", width: "28vw", height: "1px", backgroundColor: "rgba(146, 144, 152, 0.5)" }} />
            <Typography.Text style={{ textAlign: "left", fontWeight: 500, fontSize: "1.2vw", color: "rgba(15, 7, 24, 1)", position: "absolute", left: "1.5vw", top: "48vh" }}>
              Till Events
            </Typography.Text>

            <Row style={{ paddingLeft: "1.5vw", paddingTop: "21vh", paddingRight: "1.4vw", height: "20vh" }}>
              <Scrollbars style={{ width: "100%", height: "35vh" }} className="tillEventsScroll">
                {tillEvents.map((item, index) => (
                  <Col span={24} style={{ paddingTop: "1.5vh" }}>
                    <Typography.Text style={{ color: "rgba(15, 7, 24, 1)", fontSize: "1vw", fontWeight: 500 }}>{item.tillStatus} till:</Typography.Text>

                    <div style={{ paddingTop: "0.5vh" }}>
                      <div style={{ width: "25vw", height: "0.6vh", backgroundColor: "rgba(217, 217, 217, 1)", borderRadius: "5px" }}>
                        <div style={{ width: "25vw", height: "100%", backgroundColor: item.isSynced ? "rgba(0, 128, 0, 1)" : "rgba(248, 197, 25, 1)", borderRadius: "5px" }} />
                      </div>
                    </div>

                    <div style={{ position: "absolute", width: "14vw", height: "2vh", top: "6.5vh", left: "12.2vw", fontSize: "0.7vw", fontWeight: 500 }}>User : {item?.user}</div>

                    <div style={{ position: "absolute", width: "2vw", height: "2vh", top: "2.6vh", left: "26.2vw" }}>
                      <img src={item.isSynced ? TickCircle : PieChart} style={{ width: "1.4vw", height: "3vh", top: "0.8vh", left: "0.5vw", position: "absolute" }} />
                    </div>

                    <Typography.Text style={{ paddingTop: "0.4vh", position: "absolute", fontWeight: 400, fontSize: "0.7vw" }}>
                      {item.tillStatus === "open" ? item.openingTime : item.closingTime}
                    </Typography.Text>

                    <Typography.Text style={{ fontFamily: "Inter", fontSize: "0.7vw", fontWeight: 300, color: "rgba(15, 7, 24, 1)", paddingTop: "2.5vh", marginLeft: "25.5vw" }}>
                      {item.isSynced ? "Synced" : "Pending"}
                    </Typography.Text>
                  </Col>
                ))}
              </Scrollbars>
            </Row>
          </Card>
        </Col>

        <Col span={8} style={{ paddingRight: 0 }}>
          <Card style={{ borderRadius: "7px", height: "42.5vh", marginLeft: "-0.4em" }}>
            <div style={{ backgroundColor: "rgba(146, 144, 152, 0.3)", borderRadius: "7px 7px 0 0", height: "5.5vh", width: "31.80vw", paddingTop: "1.4vh", paddingLeft: "1.3vw" }}>
              <Typography.Title level={4} style={{ color: "rgba(15, 7, 24, 1)", fontSize: "1vw", fontWeight: 300 }}>
                Activity Log
              </Typography.Title>
            </div>
            <Scrollbars
              style={{ height: "35vh" }}
              renderTrackHorizontal={(props) => <div {...props} style={{ display: "none" }} className="track-horizontal" />}
              className="orderListScroll"
            >
              <Collapse bordered={false} style={{ backgroundColor: "transparent" }}>
                {logActivity.map((logData, index) => {
                  return (
                    <Panel
                      header={
                        <div style={{ fontWeight: "500", fontSize: "1vw", textAlign: "center", color: "rgba(15, 7, 24, 1)" }}>
                          {logData.title?.toString().length > 35 ? logData.title?.toString().substring(0, 35)?.concat("....") : logData.title}
                        </div>
                      }
                      key={index}
                    >
                      {Object.entries(logData).map(([key, value]) => {
                        return (
                          <p style={{ width: "40vw", height: "1vh", fontSize: "0.9vw", fontWeight: 400 }}>
                            {key} - {value?.toString().length > 40 ? value?.toString().substring(0, 40)?.concat("....") : value}
                          </p>
                        );
                      })}
                    </Panel>
                  );
                })}
              </Collapse>
            </Scrollbars>
          </Card>

          <Row gutter={24}>
            <Col span={24}>
              <Card style={{ borderRadius: "7px", height: "42.5vh", marginTop: "15px", paddingRight: 0, marginLeft: "-0.4em" }}>
                <div
                  style={{ backgroundColor: "rgba(146, 144, 152, 0.3)", borderRadius: "7px 7px 0 0", height: "5.5vh", width: "31.80vw", paddingTop: "1.4vh", paddingLeft: "1.3vw" }}
                >
                  <Typography.Title level={4} style={{ color: "rgba(15, 7, 24, 1)", fontSize: "1vw", fontWeight: 300 }}>
                    Order List
                  </Typography.Title>
                </div>

                <Typography.Text style={{ top: "7vh", position: "absolute", left: "1.5vw", fontWeight: 400, fontSize: "0.9vw" }}>Order No</Typography.Text>

                <Typography.Text style={{ top: "7vh", position: "absolute", left: "8vw", fontWeight: 400, fontSize: "0.9vw" }}>Sync Status</Typography.Text>

                <Typography.Text style={{ top: "7vh", position: "absolute", left: "14.5vw", fontWeight: 400, fontSize: "0.9vw" }}>Last Sync Time</Typography.Text>

                <div style={{ position: "absolute", top: "10.5vh", left: "1.5vw", width: "28vw", height: "1px", backgroundColor: "rgba(146, 144, 152, 0.5)" }} />

                <Row style={{ paddingLeft: "1.3vw", paddingTop: "6vh", paddingRight: "1.4vw", height: "20vh" }}>
                  <Scrollbars style={{ width: "100%", height: "35vh" }} className="orderListScroll">
                    {ordersCopy.map((order, index) => (
                      <Col span={24} key={index} style={{ paddingTop: "0.5vh" }}>
                        <Typography.Text style={{ color: "rgba(15, 7, 24, 1)", fontSize: "0.79vw" }}>{order.documentno}</Typography.Text>

                        <div style={{ position: "absolute", width: "2vw", height: "1.5vh", top: "0.1vh", left: "8.2vw" }}>
                          <img src={order.isSynced ? TickCircle : PieChart} style={{ width: "1.4vw", height: "3vh", top: "0.8vh", left: "0.5vw", position: "absolute" }} />
                        </div>

                        <Typography.Text style={{ paddingTop: "0.4vh", position: "absolute", left: "13.5vw", fontWeight: 400, fontSize: "0.9vw" }}>
                          {formatOrderTime(order.orderTime)}
                          {/* {order.orderTime} */}
                        </Typography.Text>

                        <button
                          onClick={() => {
                            syncOrders(order);
                          }}
                          style={{
                            width: "3.5vw",
                            height: "2.5vh",
                            top: "1vh",
                            left: "20.5vw",
                            padding: "8px 14px",
                            borderRadius: "2px",
                            border: "1px solid rgba(47, 56, 86, 1)",
                            backgroundColor: "rgba(47, 56, 86, 1)",
                            color: "white",
                            cursor: "pointer",
                            fontSize: "0.8vw",
                            position: "absolute",
                            outline: "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Sync
                        </button>

                        <button
                          style={{
                            width: "3.5vw",
                            height: "2.5vh",
                            top: "1vh",
                            left: "24.5vw",
                            padding: "8px 14px",
                            borderRadius: "2px",
                            border: "1px solid rgba(47, 56, 86, 1)",
                            backgroundColor: "transparent",
                            color: "rgba(47, 56, 86, 1)",
                            cursor: "pointer",
                            fontSize: "0.8vw",
                            position: "absolute",
                            outline: "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          View
                        </button>
                      </Col>
                    ))}
                  </Scrollbars>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard2;
