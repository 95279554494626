import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, Row, Col, Drawer, message,Spin } from 'antd';
// import "./style.css";
import "antd/dist/antd.css";
import db from '../../database';
import {timeStamp} from '../../utility/timestamp';
import { LoadingOutlined } from "@ant-design/icons";
import { getApprovals } from '../../components/Sync/syncData';
import { getOAuthHeaders } from '../../constants/oAuthValidation';
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Close from "../../assets/images/close-x.svg"
import Refresh from "../../assets/images/refresh.svg"
import User from "../../assets/images/User.svg"
import CancelArrow from "../../assets/images/cancelArrow.svg"
import * as Sentry from '@sentry/react';
import upsertPOSLog from '../../components/PointOfSale/Retail/posLog';

const ManagerApprovalModal = ({ event, onClose, visible, setManagerApprovalModalVisible, onApprovalGranted }) => {
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedUser,setSelectedUser] = useState();
  const [approverRole,setApproverRole] = useState();
  const [loading,setLoading] = useState(false)
  const [user,setUsers] = useState(event[0].approvers)
  const [name,setName] = useState()
  const [notes,setNotes] = useState()
  const cart = JSON.parse(localStorage.getItem("cartObj"));
  const tillData = JSON.parse(localStorage.getItem("tillData"))
  const bunitId = tillData.tillAccess.csBunit.csBunitId;
 
  let cleanToken;
  const serverUrl = process.env.REACT_APP_serverUrl;
  const authHeaders = getOAuthHeaders();
  if (authHeaders) {
    cleanToken = authHeaders.access_token;
  }


  const handleApprove = async() => {
    const selectedItem = user.filter(item => item.csUserId === selectedUser);
    const uniqueId = uuidv4().replace(/-/g, "").toUpperCase();
    let eventType;
    if(event[0].eventName === "Print Draft bill"){
      eventType="PDB"
    }else if(event[0].eventName === "Sales Return Process"){
      eventType="SRP"
    }else if(event[0].eventName === "Receipt Reprint Request"){
      eventType="RRR"
    }
    const timeMark = timeStamp();
    const data = {
      "approverID":selectedUser,
      "approverName":name.replace(/[/'']/g, ''),
      "notes":notes,
      "documentNo":cart?.documentno,
      "username":authHeaders.username,
      "role":approverRole,
      "time":timeMark }
    if (inputValue === selectedItem[0].pin) {
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data:{query: `mutation {
          upsertWorkflowEventStore(eventStore: [{
               aggregatorId:  "${uniqueId}"
               aggregatorType: "PS"
               eventType: "${eventType}"
               eventData: "${JSON.stringify(data).replace(/"/g, '\\"')}"
            }]) {
            status   
            message
          }
        } `},
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      }).catch((error) => {
        Sentry.captureException(error);
      })
      if(response.data.data.upsertWorkflowEventStore.status === "200"){
      onClose(); // Close the modal
      localStorage.setItem("approvalFlag","1");
      setManagerApprovalModalVisible(false); // Set the visibility to false
      upsertPOSLog(cart,"RRC",name.replace(/[/'']/g, ''))
      if (onApprovalGranted) {
        onApprovalGranted(true);
      }
      }else{
        Sentry.captureException(new Error("UpsertWorkFlow failed"), {
          extra: `{
            upsertWorkflowEventStore(eventStore: [{
                 aggregatorId:  "${uniqueId}"
                 aggregatorType: "PS"
                 eventType: "${eventType}"
                 eventData: "${JSON.stringify(data).replace(/"/g, '\\"')}"
              }])
            }`
        });
        message.error("Sorry, there was an error processing your request.Please contact technical support for assistance")
      }
    } else {
      setErrorMessage("Incorrect PIN entered. Please check and enter the correct PIN");
    }
  };

  const handleReject = () => {
    localStorage.setItem("approvalFlag","1");
    setManagerApprovalModalVisible(false); // Close the modal
  };

  const handleNumber = (value) => {
    if(selectedUser){
      if (inputValue === "" && value === "x") {
        setInputValue("");
      } else if (value === "x") {
        setInputValue(inputValue.toString().substring(0, inputValue.toString().length - 1));
      } 
      else if (value === "Clear") {
        setInputValue("")
      }else {
        setInputValue(`${inputValue}${value}`);
        
      }
      const pinInput = document.getElementById("pinInput");
    pinInput.focus();
    }else{
      message.error("PLease select a User")
    }
    
  };


  useEffect(() => {
    if (inputValue === "") {
      setErrorMessage(!errorMessage);
    }
  }, [inputValue,user]);
       
  const pinInput = document.getElementById("pinInput");

  const handleRefresh = async() => {
    setLoading(true)
    // await db.POSWorkFlowRules.clear();
    // await db.approvers.clear();
    await getApprovals();
    const appover = await db.approvers.toArray();
    setUsers(appover)
    localStorage.setItem("reload",true)
    setErrorMessage()
    setInputValue()
    setSelectedUser()
    setLoading(false)
  };

  
  

  return (
    <Drawer
      closable={true}
      placement="bottom"
      title={
        <div style={{ display: "flex", alignItems: "center", height: "2vh" }}>
          <p style={{ fontSize: "2vw", marginTop: "3vh", fontWeight: "500", color: "#0F0718", flex: 1, textAlign: "center" }}>{event[0]?.ruleName} Needed</p>
          <img src={Close} onClick={handleReject} style={{ float: "right", cursor: "pointer", marginTop: "-3vh", marginRight: "-0.5vw", width:"1.5vw" }} />
        </div>
      }
      closeIcon={null} 
      visible={visible}
      className="filter"
      bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
      onClose={handleReject}
      //  id="sm-drawer-close"
      height="90vh"
    >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
      <Row>
        <Col span={12}>
          <Row gutter={[16, 16]}>
            <Col span={22} push={2} style={{paddingLeft:"1.5vw"}} >
              <span style={{ fontSize: "1.2vw", fontWeight: 500 }}>Authorizer Selection</span>
              <span style={{ float: "right" }}>
                <img src={Refresh} onClick={handleRefresh} style={{ height: "1rem" ,cursor:"pointer"}} alt='' />
              </span>
            </Col>
            {user
              .filter(res => res.csBunitId === bunitId) // Filter users based on bunitId
              .sort((a, b) => a.name.localeCompare(b.name)) // Sort users in ascending order of name
              .map(res => (
                <Col span={10} push={2} style={{ background: selectedUser === res.csUserId ? "#cae5bd" : "#fff", borderRadius: "10px", display: "flex", alignItems: "center", margin: "0 1rem", padding: "0rem 0.5rem" }} onClick={() => { setSelectedUser(res.csUserId); setName(res.name); setApproverRole(res.role); pinInput.focus(); setInputValue("") }}>
                  <img src={User} alt='' style={{ marginRight: "10px", height: "2.5rem", margin: "0.7rem 0 " }} />
                  <span style={{ marginLeft: "10px", margin: "0.7rem 0px 0.7rem 10px" }}>
                    <p style={{ margin: 0, fontWeight: 500, fontSize: "1rem" }}>{res.name}</p>
                    <span style={{ fontSize: "0.8rem" }}>{res.role}</span>
                  </span>
                </Col>
              ))
            }
          </Row>
        </Col>
        <Col span={10} push={1} pull={1}>
          <Row>
            <Col span={20} pull={2} push={2}>
              <p style={{ marginBottom: "0.4rem", fontWeight: "600", fontSize: "1.5vw" }}>PIN:{" "}{errorMessage && <span style={{ color: "red",textAlign:"center",fontWeight:400,fontSize:"1.2vw" }}>{errorMessage}</span>}</p>
              <Input
                style={{ width: "100%", borderRadius: "2px", height: "8.5vh" }}
                size="large"
                className="discount-value-input"
                placeholder="Enter value"
                id="pinInput"
                type='password'
                value={inputValue} onChange={(e) => { setInputValue(e.target.value) }}
              />
            </Col>
          </Row>
          <Row style={{ padding: "1.5% 8.4% 0 8.48%" }} gutter={[8, 6]}>
            <Col span={6}>
              <button id="sm-amount-button1" className="amt-dial-btn-approve" onClick={(e) => handleNumber("1",e)} >
                1
              </button>
            </Col>
            <Col span={6}>
              <button id="sm-amount-button2" className="amt-dial-btn-approve" onClick={(e) => handleNumber("2",e)} >
                2
              </button>
            </Col>
            <Col span={6}>
              <button id="sm-amount-button3" className="amt-dial-btn-approve" onClick={(e) => handleNumber("3",e)}>
                3
              </button>
            </Col>
            <Col span={6}>
              <button className="amt-dial-btn-approve" id="sm-amount-buttonRemove" onClick={(e) => handleNumber("x",e)}>
                <img src={CancelArrow} style={{ height: "1rem" }} alt="back space" />
              </button>
            </Col>
            <Col span={6}>
              <button id="sm-amount-button4" className="amt-dial-btn-approve" onClick={(e) => handleNumber("4",e)}>
                4
              </button>
            </Col>
            <Col span={6}>
              <button id="sm-amount-button5" className="amt-dial-btn-approve" onClick={(e) => handleNumber("5",e)}>
                5
              </button>
            </Col>
            <Col span={6}>
              <button id="sm-amount-button6" className="amt-dial-btn-approve" onClick={(e) => handleNumber("6",e)}>
                6
              </button>
            </Col>
            <Col span={6}>

              <button onClick={(e) => handleNumber("Clear",e)}
                id="sm-amount-addQuantity" className="amt-dial-btn-approve" >
                Clear
              </button>

            </Col>
            <Col span={18}>
              <Row gutter={[8, 6]}>
                <Col span={8}>
                  <button id="sm-amount-button7" className="amt-dial-btn-approve" onClick={(e) => handleNumber("7",e)}>
                    7
                  </button>
                </Col>
                <Col span={8}>
                  <button id="sm-amount-button8" className="amt-dial-btn-approve" onClick={(e) => handleNumber("8",e)}>
                    8
                  </button>
                </Col>
                <Col span={8}>
                  <button id="sm-amount-button0" className="amt-dial-btn-approve" onClick={(e) => handleNumber("9",e)}>
                    9
                  </button>
                </Col>
                <Col span={8}>
                  <button id="sm-amount-button0" className="amt-dial-btn-approve" onClick={(e) => handleNumber("0",e)}>
                    0
                  </button>
                </Col>
                <Col span={8}>
                  <button id="sm-amount-button00" className="amt-dial-btn-approve" onClick={(e) => handleNumber("00",e)}>
                    00
                  </button>
                </Col>
                <Col span={8}>
                  <button id="sm-amount-button." className="amt-dial-btn-approve" onClick={(e) => handleNumber(".",e)}>
                    .
                  </button>
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <button disabled={selectedUser?false:true} id="sm-amount-buttonEnter" className="amt-dial-btn-approve2" onClick={handleApprove}>
                Enter
              </button>
            </Col>
          </Row>
          <Row style={{ paddingTop: "1%" }}>
            <Col span={20} pull={2} push={2}>
              <Input
                style={{ width: "100%", borderRadius: "2px", height: "8.5vh" }}
                size="large"
                className="discount-value-input"
                placeholder="Type to add a note"
                value={notes}
                onChange={(e)=>{setNotes(e.target.value)}}
              />
            </Col>
            <Col span={20} pull={2} push={2} style={{ padding: "1.5% 0" }}>
              <Button disabled={selectedUser?false:true} onClick={handleApprove} htmlType="submit" style={{ backgroundColor: '#2F3856', color: '#fff', width: "100%", fontSize: "1.2vw", textAlign: "center", fontWeight: "400", borderRadius: "7px", height: "8vh" }}>
                Approve
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      </Spin>
    </Drawer>
  );
};

export default ManagerApprovalModal;
